<template>
  <div class="add-many-seances" v-if="hasPerm('youth.add_seance')">
    <b-form>
      <h3>Ajout de séances</h3>
      <div class="field-line top-right-holder">
        <div class="top-right">
          <a href class="btn btn-sm btn-secondary" @click.prevent="selectAllDays">Tous</a>
          <a href class="btn btn-sm btn-secondary" @click.prevent="selectTypeDays">Type</a>
          <a href class="btn btn-sm btn-secondary" @click.prevent="unselectAllDays">Aucun</a>
        </div>
        <b-form-group
          id="filter-group-weekday"
          label="Jours de la semaine"
          label-for="filter-weekday"
        >
          <div class="inline space-right" v-for="weekday in daysOfTheWeek" :key="weekday.id">
            <b-checkbox
              v-model="selectedWeekDays[weekday.id]"
              :name="weekday.name"
              :value="true"
              :unchecked-value="false"
            >
              {{ weekday.name }}
            </b-checkbox>
          </div>
        </b-form-group>
      </div>
      <div class="field-line top-right-holder">
        <div class="top-right">
          <a href class="btn btn-sm btn-secondary" @click.prevent="selectAllCodes">Tous</a>
          <a href class="btn btn-sm btn-secondary" @click.prevent="unselectAllCodes">Aucun</a>
        </div>
        <b-form-group
          id="filter-group-codes"
          label="Modèles de séances"
          label-for="filter-codes"
        >
          <div class="inline space-right" v-for="elt in seanceTemplates" :key="elt.id">
            <b-checkbox
              v-model="selectedSeanceTemplates['' + elt.id]"
              :name="elt.code"
              :value="true"
              :unchecked-value="false"
            >
              <span v-b-tooltip="elt.name ">{{ elt.code }}</span>
            </b-checkbox>
          </div>
          <div v-if="hasFixedFee" class="warning-message">
            Attention: Vous avez sélectionné au moins un gabarit "Forfait"
          </div>
        </b-form-group>
        <b-form-group
          id="label-forceName"
          label=""
          label-for="forceName"
          description="Possible si seulement 1 gabarit est actif. Les séances prendront ce nom plutôt que celui du gabarit"
        >
          <b-form-checkbox v-model="allowForceName" :disabled="!canForceName">
            Forcer le nom des séances
          </b-form-checkbox>
          <b-input type="text" v-model="forceName" :disabled="!allowForceName"></b-input>
        </b-form-group>
        <b-form-group
          v-if="allowForceFirstAsParent"
          id="label-forceFirstAsParent"
          label=""
          label-for="forceFirstAsParent"
          description="L'inscription se fera sur la première séance qui forcera l'inscription aux séances secondaires"
        >
          <b-form-checkbox v-model="forceFirstAsParent">
            Créer la première séance comme séance principale
            ({{ forceFirstAsParentCodes.join(', ') }})
          </b-form-checkbox>
        </b-form-group>
      </div>
      <loading-gif :loading-name="bankHolidaysLoadingName"></loading-gif>
      <div class="field-line top-right-holder" v-if="!isLoading(bankHolidaysLoadingName)">
        <div class="top-right">
          <a href class="btn btn-sm btn-secondary" @click.prevent="selectAllBankHolidays">Tous</a>
          <a href class="btn btn-sm btn-secondary" @click.prevent="unselectAllBankHolidays">Aucun</a>
        </div>
        <b-form-group
          id="filter-group-bank-holidays"
          label="Jours fériés"
          label-for="bank-holidays"
          description="Cocher les jours fériés pour lesquels vous souhaitez créer les séances"
        >
          <div class="inline space-right" v-for="elt in periodBankHolidays" :key="elt.id">
            <b-checkbox
              v-model="selectedBankHolidays[elt.id]"
              :name="'' + elt.id"
              :value="true"
              :unchecked-value="false"
            >
              <span v-b-tooltip="elt.name">{{ elt.date | dateToString('ddd LL') }}</span>
            </b-checkbox>
          </div>
        </b-form-group>
      </div>
      <div class="field-line top-right-holder">
        <div class="top-right">
          <a href class="btn btn-sm btn-secondary" @click.prevent="selectThisWeek">Cette semaine</a>
          <a href class="btn btn-sm btn-secondary" @click.prevent="selectNextWeek">La semaine prochaine</a>
          <a href class="btn btn-sm btn-secondary" @click.prevent="selectThisMonth">Ce mois</a>
          <a href class="btn btn-sm btn-secondary" @click.prevent="selectNextMonth">Le mois prochain</a>
        </div>
        <b-form-group
          id="filter-group-date-to-to"
          label="Date à date"
          label-for="filter-date-to-date"
        >
          <b-row>
            <b-col>
              <b>Du</b><br />
              <b-input
                name="date-from"
                v-model="dateFrom"
                type="date"
                @change="reset()"
              ></b-input>
              <div class="date-name">{{ dateFromWeekDay }}</div>
              <div class="date-setters">
                <a
                  v-for="setter of fromSetters"
                  :key="setter"
                  class="btn btn-xs btn-secondary"
                  v-b-tooltip="dateSetterTooltip(setter)"
                  @click.prevent="setDateTo('from', setter)"
                >
                  {{ setter }}
                </a>
              </div>
            </b-col>
            <b-col>
              <b>Au</b><br />
              <b-input
                name="date-to"
                v-model="dateTo"
                type="date"
                @change="reset()"
              ></b-input>
              <div class="date-name">{{ dateToWeekDay }}</div>
              <div class="date-setters">
                <a
                  v-for="setter of toSetters"
                  :key="setter"
                  class="btn btn-xs btn-secondary"
                  v-b-tooltip="dateSetterTooltip(setter)"
                  @click.prevent="setDateTo('to', setter)"
                >
                  {{ setter }}
                </a>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div class="timeframe-warning" v-if="timeframeWarning">{{ timeframeWarning }}</div>
            </b-col>
          </b-row>
        </b-form-group>
      </div>
      <loading-gif :loading-name="analyticAccountsLoadingName"></loading-gif>
      <div class="field-line" v-if="!isLoading(analyticAccountsLoadingName) && analyticAccounts.length > 0">
        <b-row>
          <b-col>
            <b-form-group
              id="custom-analytic-group"
              label="Compte analytique"
              label-for="custom-analytic"
            >
              <b-checkbox
                v-model="useCustomAnalyticAccount"
                name="custom-analytic"
                :value="true"
                :unchecked-value="false"
                @change="reset()"
              >
                Personnaliser le code analytique
              </b-checkbox>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              id="analyticGroup"
              label="Compte personnalisé"
              label-for="analytic"
              v-if="useCustomAnalyticAccount"
            >
              <b-form-select
                id="analytic"
                v-model="selectedAnalyticAccountId"
                @change="reset()"
              >
                <b-form-select-option
                  :value="analyticAccount.id"
                  v-for="analyticAccount in analyticAccounts"
                  :key="analyticAccount.id"
                >
                  {{ analyticAccount.name }}
                </b-form-select-option>
              </b-form-select>
            </b-form-group>
            <div v-if="!useCustomAnalyticAccount">
              <div class="small2">
                Le code utilisé sera calculé à partir des règles définis
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-row class="buttons-bar">
        <b-col class="text-left" cols="3">
          <a class="btn btn-secondary" href @click.prevent="onCancel()">Annuler</a>
        </b-col>
        <b-col class="text-right" cols="9">
          <a
            class="btn btn-primary"
            :class="{'disabled': !isDataValid, }"
            href
            @click.prevent="onPreview()"
          >
            Prévisualiser
          </a>
        </b-col>
      </b-row>
    </b-form>
    <div v-if="showPreview" class="preview-list">
      <div class="preview-header">
        Séances à créer
      </div>
      <div class="preview-content">
        <loading-gif :loading-name="createSeancesLoadingName"></loading-gif>
        <div v-if="!isLoading(createSeancesLoadingName)">
          <div v-if="previewSeances.length === 0">
            <i class="empty-text">Aucune séance</i>
          </div>
          <div v-else>
            <b><counter-label label="séance" :counter="previewSeances.length"></counter-label></b>
          </div>
        </div>
        <div
          v-for="seance of previewSeances"
          :key="seance.code"
          class="preview-line"
          :class="{ 'existing': seance.id > 0, 'ignored': isSeanceIgnored(seance)}"
        >
          <b-row>
            <b-col cols="3">
              {{ seance.date | dateToString('ddd ll') }}
            </b-col>
            <b-col cols="6">
              {{ seance.getShortName() }}
              <span class="badge badge-light" v-b-tooltip="seance.code">{{ seance.getCodeName() }}</span>
              &nbsp;
              <span
                class="badge badge-light"
                v-b-tooltip="seance.seanceAnalyticAccount.code"
                v-if="seance.seanceAnalyticAccount.id">
                {{ seance.seanceAnalyticAccount.name }}
              </span>
              <span class="badge badge-warning" v-else>
                Pas de code analytique
              </span>
              <span class="badge badge-warning" v-if="seance.fixedFee">
                Forfait
              </span>
            </b-col>
            <b-col cols="3" class="text-right">
              <div v-if="seance.id">Existe déjà</div>
              <div v-else>
                <a class="btn btn-sm btn-secondary" href @click.prevent="ignoreSeance(seance)">
                  <i class="fa fa-times"></i>
                </a>
              </div>
            </b-col>
          </b-row>
        </div>
        <b-row class="buttons-bar">
          <b-col class="text-right" cols="12">
            <a
              :class="{ 'disabled': previewSeances.length === 0, }"
              class="btn btn-primary"
              href
              @click.prevent="onCreate()"
            >
              Créer les séances
            </a>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapMutations, mapActions } from 'vuex'
import CounterLabel from '@/components/Controls/CounterLabel'
import LoadingGif from '@/components/Controls/LoadingGif'
import { BackendMixin } from '@/mixins/backend'
import store from '@/store'
import { makeBankHoliday } from '@/types/schools'
import { makeSeance, makeSeanceAnalyticAccount } from '@/types/youth'
import { BackendApi } from '@/utils/http'

export default {
  name: 'AddManySeancesForm',
  components: {
    CounterLabel,
    LoadingGif,
  },
  mixins: [BackendMixin],
  props: {
    youthHome: Object,
    seanceType: Object,
    periods: {
      type: Array,
      defaultValue: [],
    },
    seanceTemplates: Array,
  },
  data() {
    return {
      selectedWeekDays: [],
      selectedSeanceTemplates: {},
      dateFrom: null,
      dateTo: null,
      previewSeances: [],
      createSeancesLoadingName: 'addManySeances',
      analyticAccountsLoadingName: 'seanceAnalyticAccounts',
      bankHolidaysLoadingName: 'bankHolidays',
      showPreview: false,
      fromSetters: ['J-1', 'J0', 'J+1', 'S+1', 'M+1', '>S', '>M'],
      toSetters: ['J-1', 'J0', 'J+1', 'S+1', 'M+1', 'S>', 'M>'],
      ignoredCodes: {},
      bankHolidays: [],
      selectedBankHolidays: {},
      analyticAccounts: [],
      useCustomAnalyticAccount: false,
      selectedAnalyticAccountId: 0,
      periodsDateFrom: null,
      periodsDateTo: null,
      forceName: '',
      allowForceName: false,
      forceFirstAsParent: false,
    }
  },
  created() {
    this.selectedWeekDays = this.weekDays || [ ...Array(7).keys() ].map(elt => true)
    for (let seanceTemplate of this.seanceTemplates) {
      if (!this.selectedSeanceTemplates.hasOwnProperty(seanceTemplate.code)) {
        this.selectedSeanceTemplates[seanceTemplate.id] = true
      }
    }
    this.selectedSeanceTemplates = { ...this.selectedSeanceTemplates, }
    this.setTimeframe()
    this.setTypeDays()
    this.loadBankHolidays()
    this.loadSeanceAnalyticAccounts()
  },
  computed: {
    periodBankHolidays() {
      if (this.dateFrom && this.dateTo) {
        return this.bankHolidays.filter(
          elt => {
            return ((elt.date >= this.dateFrom) && (elt.date <= this.dateTo))
          }
        )
      } else {
        return []
      }
    },
    daysOfTheWeek() {
      return [ ...Array(7).keys() ].map(
        index => {
          return { id: index, name: moment.weekdays(true, index), }
        }
      )
    },
    dateFromWeekDay() {
      if (this.dateFrom) {
        return moment(this.dateFrom).format('dddd LL')
      }
      return '-'
    },
    dateToWeekDay() {
      if (this.dateTo) {
        return moment(this.dateTo).format('dddd LL')
      }
      return '-'
    },
    isDataValid() {
      return (
        this.dateFrom && this.dateTo && this.dateFrom <= this.dateTo &&
        (!this.useCustomAnalyticAccount || this.selectedAnalyticAccountId !== 0)
      )
    },
    activeSeanceTemplates() {
      return this.seanceTemplates.filter(elt => this.selectedSeanceTemplates[elt.id])
    },
    hasFixedFee() {
      return this.activeSeanceTemplates.filter(elt => elt.fixedFee).length > 0
    },
    timeframeWarning() {
      const warnings = []
      if ((this.periodsDateFrom) && (this.dateFrom < this.periodsDateFrom)) {
        warnings.push('La date de début précède celle des périodes sélectionnées.')
      }
      if ((this.periodsDateTo) && (this.dateTo > this.periodsDateTo)) {
        warnings.push('La date de fin dépasse celle des périodes sélectionnées.')
      }
      if (warnings.length) {
        warnings.push('Seules seront créées les séances dont la date correspond à une période.')
      }
      return warnings.join(' ')
    },
    canForceName() {
      let selected = 0
      for (let tplt of this.seanceTemplates) {
        if (this.selectedSeanceTemplates['' + tplt.id]) {
          selected += 1
        }
      }
      return selected === 1
    },
    forceFirstAsParentCodes() {
      const codes = []
      for (let tplt of this.seanceTemplates) {
        if (this.selectedSeanceTemplates['' + tplt.id]) {
          if (tplt.allowForceFirstAsParent) {
            codes.push(tplt.code)
          }
        }
      }
      return codes
    },
    allowForceFirstAsParent() {
      return this.forceFirstAsParentCodes.length > 0
    },
  },
  watch: {
    seanceTemplates: function(seanceTemplates) {
      for (let seanceTemplate of seanceTemplates) {
        if (!this.selectedSeanceTemplates.hasOwnProperty(seanceTemplate.code)) {
          this.selectedSeanceTemplates['' + seanceTemplate.id] = true
        }
      }
      this.selectedSeanceTemplates = { ...this.selectedSeanceTemplates, }
    },
    canForceName() {
      this.allowForceName = false
      this.forceName = ''
    },
    youthHome: function() {
      this.setTimeframe()
      this.reset()
      this.loadBankHolidays()
    },
    seanceType: function() {
      this.setTypeDays()
      this.reset()
    },
    periods: function() {
      this.setTimeframe()
      this.reset()
    },
    selectedWeekDays: function() { this.reset() },
    selectedSeanceTemplates: {
      handler: function() {
        this.reset()
      },
      deep: true,
    },
    selectedBankHolidays: {
      handler: function() {
        this.reset()
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    selectAllDays() {
      let values = this.selectedWeekDays.filter(elt => true)
      for (let index = 0; index < values.length; index++) {
        values[index] = true
      }
      this.selectedWeekDays = values
    },
    selectTypeDays() {
      if (this.seanceType) {
        this.selectedWeekDays = this.seanceType.getDays()
      }
    },
    unselectAllDays() {
      let values = this.selectedWeekDays.filter(elt => true)
      for (let index = 0; index < values.length; index++) {
        values[index] = false
      }
      this.selectedWeekDays = values
    },
    selectThisWeek() {
      let now = moment()
      this.dateFrom = now.clone().format('YYYY-MM-DD')
      this.dateTo = now.clone().weekday(6).format('YYYY-MM-DD')
    },
    selectNextWeek() {
      let now = moment()
      this.dateFrom = now.clone().weekday(0).add(7, 'days').format('YYYY-MM-DD')
      this.dateTo = now.clone().weekday(6).add(7, 'days').format('YYYY-MM-DD')
    },
    selectThisMonth() {
      let now = moment()
      this.dateFrom = now.format('YYYY-MM-DD')
      this.dateTo = now.endOf('month').format('YYYY-MM-DD')
    },
    selectNextMonth() {
      let now = moment()
      let startOfNextMonth = now.startOf('month').add(1, 'month')
      this.dateFrom = startOfNextMonth.format('YYYY-MM-DD')
      this.dateTo = startOfNextMonth.endOf('month').format('YYYY-MM-DD')
    },
    selectAllCodes() {
      for (let template of this.seanceTemplates) {
        this.selectedSeanceTemplates['' + template.id] = true
      }
      this.selectedSeanceTemplates = { ...this.selectedSeanceTemplates, }
    },
    unselectAllCodes() {
      for (let template of this.seanceTemplates) {
        this.selectedSeanceTemplates[template.id] = false
      }
      this.selectedSeanceTemplates = { ...this.selectedSeanceTemplates, }
    },
    selectAllBankHolidays() {
      for (let elt of this.periodBankHolidays) {
        this.selectedBankHolidays[elt.id] = true
      }
      this.selectedBankHolidays = { ...this.selectedBankHolidays, }
    },
    unselectAllBankHolidays() {
      for (let elt of this.periodBankHolidays) {
        this.selectedBankHolidays[elt.id] = false
      }
      this.selectedBankHolidays = { ...this.selectedBankHolidays, }
    },
    setTypeDays() {
      if (this.seanceType) {
        this.selectedWeekDays = this.seanceType.getDays()
      }
    },
    setTimeframe() {
      if (this.periods && this.youthHome && this.youthHome.schoolYear) {
        let dateFrom = null
        let dateTo = null
        for (let period of this.periods) {
          let timeframe = period.getTimeframe(this.youthHome.schoolYear.id)
          if (timeframe.length >= 2) {
            if ((dateFrom === null) || (timeframe[0] < dateFrom)) {
              dateFrom = timeframe[0]
            }
            if ((dateTo === null) || (timeframe[1] > dateTo)) {
              dateTo = timeframe[1]
            }
          }
        }
        this.dateFrom = dateFrom
        this.dateTo = dateTo
        this.periodsDateFrom = dateFrom
        this.periodsDateTo = dateTo
      }
    },
    dateSetterTooltip(setter) {
      if (setter === 'J0') {
        return 'Aujourd\'hui'
      } else if (setter === 'J-1') {
        return 'Veille'
      } else if (setter === 'J+1') {
        return 'Lendemain'
      } else if (setter === 'S+1') {
        return 'Semaine suivante'
      } else if (setter === 'M+1') {
        return 'Mois suivant'
      } else if (setter === '>S') {
        return 'Début de semaine'
      } else if (setter === '>M') {
        return 'Début de mois'
      } else if (setter === 'S>') {
        return 'Fin de semaine'
      } else if (setter === 'M>') {
        return 'Fin de mois'
      }
    },
    setDateTo(field, setter) {
      let dateValue = (field === 'from') ? this.dateFrom : this.dateTo
      let otherValue = (field === 'from') ? this.dateTo : this.dateFrom
      let now = moment()
      if (!dateValue) {
        if (field === 'to' && otherValue) {
          dateValue = moment(otherValue)
        } else {
          dateValue = now.clone()
        }
      } else {
        dateValue = moment(dateValue)
      }
      if (!otherValue) {
        otherValue = dateValue.clone()
      } else {
        otherValue = moment(otherValue)
      }
      if (setter === 'J0') {
        dateValue = now.clone()
      } else if (setter === 'J+1') {
        dateValue = dateValue.add(1, 'days')
      } else if (setter === 'J-1') {
        dateValue = dateValue.add(-1, 'days')
      } else if (setter === 'S+1') {
        dateValue = dateValue.add(1, 'weeks')
      } else if (setter === 'M+1') {
        dateValue = dateValue.add(1, 'months')
      } else if (setter === '>S') {
        dateValue = otherValue.startOf('week')
      } else if (setter === '>M') {
        dateValue = otherValue.startOf('month')
      } else if (setter === 'S>') {
        dateValue = otherValue.endOf('week')
      } else if (setter === 'M>') {
        dateValue = otherValue.endOf('month')
      }
      if (field === 'from') {
        this.dateFrom = dateValue.format('YYYY-MM-DD')
      } else {
        this.dateTo = dateValue.format('YYYY-MM-DD')
      }
    },
    reset() {
      this.previewSeances = []
      this.showPreview = false
    },
    onCancel() {
      this.$emit('cancel', {})
    },
    getData() {
      let ignoredCodes = []
      let ignoredDates = []
      for (let code of Object.keys(this.ignoredCodes)) {
        if (this.ignoredCodes[code]) {
          ignoredCodes.push(code)
        }
      }
      for (let bankHoliday of this.periodBankHolidays) {
        if (
          !this.selectedBankHolidays.hasOwnProperty(bankHoliday.id) ||
          !this.selectedBankHolidays[bankHoliday.id]
        ) {
          ignoredDates.push(bankHoliday.date)
        }
      }
      return {
        youth_home: this.youthHome.id,
        seance_type: this.seanceType.id,
        periods: this.periods.map(elt => elt.id),
        date_from: this.dateFrom,
        date_to: this.dateTo,
        seance_templates: this.activeSeanceTemplates.map(elt => elt.id),
        week_days: this.daysOfTheWeek.map(elt => elt.id).filter(elt => this.selectedWeekDays[elt]),
        ignored_codes: ignoredCodes,
        ignored_dates: ignoredDates,
        seance_analytic_account: this.useCustomAnalyticAccount ? this.selectedAnalyticAccountId : 0,
      }
    },
    async loadSeances(preview) {
      if (this.youthHome && this.seanceType && this.periods && this.periods.length) {
        this.startLoading(this.createSeancesLoadingName)
        let url = '/api/youth/seances/create_many_seances/'
        let backendApi = new BackendApi('post', url)
        try {
          let data = this.getData()
          if (this.allowForceName && this.forceName) {
            data['force_name'] = this.forceName
          }
          if (this.allowForceFirstAsParent && this.forceFirstAsParent) {
            data['force_first_as_parent'] = true
          }
          data.preview = preview
          let resp = await backendApi.callApi(data)
          let seances = resp.data.map(elt => makeSeance(elt))
          if (preview) {
            this.previewSeances = seances
          } else {
            this.previewSeances = []
            let message = ''
            if (seances.length === 0) {
              message = 'Aucune séance n\'a été créée'
            } else if (seances.length === 1) {
              message = 'Une séance a été créée'
            } else {
              message = '' + seances.length + ' séances ont été créées'
            }
            await this.addSuccess(message)
            this.$emit('created', seances)
          }
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.endLoading(this.createSeancesLoadingName)
      }
    },
    async loadBankHolidays() {
      if (this.youthHome && this.youthHome.schoolYear && this.youthHome.schoolYear.id) {
        this.startLoading(this.bankHolidaysLoadingName)
        let url = '/api/bank-holidays/' + this.youthHome.schoolYear.id + '/'
        let backendApi = new BackendApi('get', url)
        try {
          let resp = await backendApi.callApi()
          this.bankHolidays = resp.data.map(elt => makeBankHoliday(elt))
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.endLoading(this.bankHolidaysLoadingName)
      }
    },
    async loadSeanceAnalyticAccounts() {
      this.startLoading(this.analyticAccountsLoadingName)
      let url = '/api/youth/seance-analytic-accounts/'
      let backendApi = new BackendApi('get', url)
      try {
        let resp = await backendApi.callApi()
        this.analyticAccounts = resp.data.map(elt => makeSeanceAnalyticAccount(elt))
      } catch (err) {
        this.addError(this.getErrorText(err))
      }
      this.endLoading(this.analyticAccountsLoadingName)
    },
    onPreview() {
      this.showPreview = true
      if (this.isDataValid) {
        this.loadSeances(true)
      }
    },
    onCreate() {
      this.loadSeances(false)
    },
    ignoreSeance(seance) {
      if (this.ignoredCodes.hasOwnProperty(seance.code)) {
        this.ignoredCodes[seance.code] = !this.ignoredCodes[seance.code]
      } else {
        this.ignoredCodes[seance.code] = true
      }
      this.ignoredCodes = { ...this.ignoredCodes, }
    },
    isSeanceIgnored(seance) {
      if (this.ignoredCodes.hasOwnProperty(seance.code)) {
        return this.ignoredCodes[seance.code]
      }
      return false
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.add-many-seances {
  padding: 10px;
  background: #e0e0e0;
  margin-bottom: 10px;
}
.inline {
  display: inline-block;
}
.space-right {
  margin-right: 25px;
}
.space-right .custom-control {
}
.top-right-holder {
  position: relative;
}
.top-right {
  position: absolute;
  right: 5px;
  top: 0;
}
.top-right a {
  margin-left: 5px;
}
.preview-list {
  margin-top: 20px;
  border: solid 1px #444;
  border-radius: 4px;
}
.preview-header {
  margin-bottom: 5px;
  border-bottom: solid 1px #444;
  font-weight: bold;
  padding: 5px;
}
.preview-content {
  padding: 5px;
}
.preview-line {
  padding: 3px;
}
.preview-line:nth-of-type(even) {
  background: #f0f0f0;
}
.preview-list .existing {
  color: #aaa;
  font-style: italic;
}
.preview-list .ignored {
  color: #aaa;
  text-decoration: line-through;
}
.preview-list .buttons-bar {
  margin-top: 10px;
}
.date-setters .btn {
  min-width: 30px;
  padding: 2px;
  margin: 2px;
}
.date-name {
  font-size: 12px;
  color: #444;
}
.timeframe-warning, .warning-message {
  background: #ffb871;
  color: #000;
  font-size: 12px;
  padding: 3px;
}
</style>
