<template>
  <span class="moment-indicator">
    <span :title="label(0)" v-if="isShown(0)" v-b-tooltip.hover :style="indicatorStyle(morning)">
      {{ letter(0) }}
    </span>
    <span :title="label(1)" v-b-tooltip.hover v-if="isShown(1)" :style="indicatorStyle(lunch)">
      {{ letter(1) }}
    </span>
    <span :title="label(4)"  v-b-tooltip.hover v-if="isShown(4)" :style="indicatorStyle(picnic)">
      {{ letter(4) }}
    </span>
    <span :title="label(2)"  v-b-tooltip.hover v-if="isShown(2)" :style="indicatorStyle(afternoon)">
      {{ letter(2) }}
    </span>
    <span :title="label(3)"  v-b-tooltip.hover v-if="isShown(3)" :style="indicatorStyle(evening)">
      {{ letter(3) }}
    </span>
  </span>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>

export default {
  name: 'MomentIndicator',
  props: {
    morning: Number,
    lunch: Number,
    afternoon: Number,
    evening: Number,
    picnic: Number,
    showEvening: {
      type: Boolean,
      default: true,
    },
    showPicnic: {
      type: Boolean,
      default: false,
    },
    showLunch: {
      type: Boolean,
      default: true,
    },
    smaller: {
      type: Boolean,
      default: false,
    },
    labels: Array,
    letters: Array,
  },
  watch: {
    showEvening: function() {},
    showLunch: function() {},
    showPicnic: function() {},
  },
  methods: {
    indicatorStyle(value) {
      const size = this.smaller ? '15px' : '22px'
      const padding = this.smaller ? '3px 1px' : '5px 3px'
      const fontSize = this.smaller ? '7px' : '9px'
      const style = {
        'font-family': 'monospace',
        'font-size': fontSize + ' !important',
        'border': 'none',
        'padding': padding,
        'border-radius': '50%',
        'display': 'inline-block',
        'margin': '0 1px',
        'width': size + ' !important',
        'height': size + ' !important',
        'text-align': 'center',
      }
      if (value) {
        style.background = '#444 !important'
        style.color = '#fff !important'
      } else {
        style.background = '#ccc !important'
        style.color = '#888 !important'
      }
      return style
    },
    label(index) {
      const labels = this.labels || ['Matin', 'Repas', 'Après-Midi', 'Soirée', 'Pique-Nique']
      if (index < labels.length) {
        return labels[index]
      }
      return ''
    },
    letter(index) {
      let letters = this.letters
      if (!letters || letters.length === 0) {
        letters = ['M', 'R', 'AM', 'S', 'PN']
      }
      if (index < letters.length) {
        return letters[index]
      }
      return ''
    },
    isShown(index) {
      if (index === 1 && !this.showLunch) {
        return false
      }
      if (index === 4 && !this.showPicnic) {
        return false
      }
      if (index === 3 && !this.showEvening) {
        return false
      }
      return this.letter(index) !== ''
    },
  },
}
</script>
<style lang="less">
</style>
