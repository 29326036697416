<template>
  <span class="seance-moment">
    <moment-indicator
      :morning="seance.morning ? 1 : 0"
      :lunch="seance.lunch ? 1 : 0"
      :afternoon="seance.afternoon ? 1 : 0"
      :evening="seance.evening ? 1 : 0"
      :picnic="seance.picnic ? 1 : 0"
      :show-evening="showEvening"
      :show-picnic="showPicnic"
      :show-lunch="showLunch"
    >
    </moment-indicator>
  </span>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>

import MomentIndicator from '@/components/Youth/MomentIndicator'

export default {
  name: 'SeanceMomentIndicator',
  components: { MomentIndicator, },
  props: {
    seance: Object,
    showEvening: {
      type: Boolean,
      default: true,
    },
    showPicnic: {
      type: Boolean,
      default: true,
    },
    showLunch: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
    }
  },
  watch: {
    seance: function() {},
    showEvening: function() {},
    showLunch: function() {},
    showPicnic: function() {},
  },
  computed: {
  },
  methods: {
  },
  mounted() {
  },
}
</script>
<style lang="less">
</style>
