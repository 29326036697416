<template>
  <div class="confirm-delete-seance-modal" v-if="hasPerm('youth.delete_seance')">
    <b-modal
      id="bv-confirm-delete-seance-modal"
      cancel-title="Non"
      @ok="onConfirm"
      @cancel="onCancel"
      ok-variant="primary"
      ok-title="Oui"
    >
      <template v-slot:modal-title>
        {{ title }}
      </template>
      <b-form ref="form">
        {{ text }}
        <loading-gif :loading-name="loadingName"></loading-gif>
        <div v-if="!isLoading(loadingName)">
          <div class="error-text" v-if="errorText">
            <i class="fa fa-warning"></i>
            Erreur lors de vérification des inscriptions: {{ errorText }}
          </div>
          <div v-else>
            <div class="warning-text" v-if="warningText">
              <i class="fa fa-warning"></i>
              {{ warningText }}
            </div>
            <div class="no-warning-text" v-if="noWarningText">
              <i class="fa fa-check"></i>
              {{ noWarningText }}
            </div>
          </div>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
import { BackendMixin } from '@/mixins/backend'
import { BackendApi } from '@/utils/http'
import { mapMutations } from 'vuex'
import LoadingGif from '@/components/Controls/LoadingGif'
import store from '@/store'

export default {
  name: 'ConfirmDeleteSeanceModal',
  components: {
    LoadingGif,
  },
  mixins: [BackendMixin],
  props: {
    text: String,
    title: {
      type: String,
      defaultValue: 'Confirmation',
    },
    object: {
      type: Object,
      defaultValue: null,
    },
  },
  data() {
    return {
      loadingName: 'seance-before-delete',
      errorText: '',
      hasInscriptions: false,
      hasInvoicedInscriptions: false,
    }
  },
  watch: {
    object: function() {
      this.loadBeforeDelete()
    },
  },
  mounted() {
    this.loadBeforeDelete()
  },
  computed: {
    warningText() {
      if (this.hasInvoicedInscriptions) {
        let text = 'La séance a des inscriptions, dont certaines sont déjà facturées. '
        text += 'La suppression de la séance entraînera l\'annulation des inscriptions associées et la création '
        text += 'd\'un remboursement pour chaque inscription facturée'
        return text
      } else if (this.hasInscriptions) {
        let text = 'La séance a des inscriptions. La suppression de la séance entraînera l\'annulation '
        text += 'des inscriptions associées'
        return text
      } else {
        return ''
      }
    },
    noWarningText() {
      if ((this.hasInvoicedInscriptions) || (this.hasInscriptions)) {
        return ''
      } else {
        return 'Aucune inscription pour cette séance'
      }
    },
  },
  methods: {
    ...mapMutations(['startLoading', 'endLoading']),
    onConfirm() {
      this.$emit('confirmed', { object: this.object, })
    },
    onCancel() {
      this.$emit('cancelled', { object: this.object, })
    },
    async loadBeforeDelete() {
      if (this.object && this.object.id) {
        this.errorText = ''
        this.hasInscriptions = false
        this.hasInvoicedInscriptions = false
        this.startLoading(this.loadingName)
        let url = '/api/youth/seances/' + this.object.id + '/before_delete/'
        let backendApi = new BackendApi('get', url)
        try {
          let resp = await backendApi.callApi()
          this.hasInscriptions = (resp.data['inscriptions_count'] || 0) > 0
          this.hasInvoicedInscriptions = (resp.data['invoiced_inscriptions_count'] || 0) > 0
        } catch (err) {
          this.errorText = this.getErrorText(err)
        }
        this.endLoading(this.loadingName)
      }
    },
  },
}
</script>
<style lang="less" scoped>
</style>
