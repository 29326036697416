<template>
  <div class="seance-workshops-modal" v-if="hasPerm('youth.view_workshop')">
    <b-modal
      size="xl"
      :id="modalId"
      cancel-title="Annuler"
      @cancel="onCancel"
      @ok="onSave"
      ok-variant="primary"
      ok-title="Enregistrer"
      :ok-disabled="!isSaveActive || readOnly"
    >
      <template v-slot:modal-title>
        <span v-if="obj.id">Ateliers {{ obj.name }}</span>
      </template>
      <b-form ref="form" v-if="obj.id">
        <div v-if="errors" class="error-text">
          {{ errors }}
        </div>
        <b-row>
          <b-col class="col-border">
            <b-row>
              <b-col>
                <h4>Ateliers</h4>
              </b-col>
              <b-col class="text-right">
                <a v-if="!readOnly" href class="btn btn-primary btn-sm ut-add-workshop" @click.prevent="addWorkshop()">
                  <i class="fa fa-plus"></i>
                </a>
              </b-col>
            </b-row>
            <b-row v-if="workshops.length === 0">
              <b-col><div class="empty-text">Aucun atelier n'est défini</div></b-col>
            </b-row>
            <b-row>
              <b-col>
                <div
                  v-for="workshop of workshops"
                  :key="workshop.id"
                  class="workshop-line"
                  :class="{ 'selected': workshop === selectedWorkshop, }"
                  @click="selectWorkshop(workshop)"
                >
                  <b-row>
                    <b-col cols="5">
                      <span :title="workshop.id">{{ workshop.name }}</span>
                    </b-col>
                    <b-col cols="3">
                      {{ getMomentName(workshop.moment) }}
                    </b-col>
                    <b-col cols="2">
                      {{ workshop.price | currency }}
                    </b-col>
                    <b-col cols="2" class="text-right">
                      <a v-if="!readOnly" class="btn btn-danger btn-sm" @click.prevent="removeWorkshop(workshop)">
                        <i class="fa fa-times"></i>
                      </a>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="2">
                      Max: {{ workshop.maxNumber }}
                    </b-col>
                    <b-col>Le prix prend en compte les réductions: {{ workshop.discountable | yesno }}</b-col>
                  </b-row>
                </div>
              </b-col>
            </b-row>
          </b-col>
          <b-col>
            <div v-if="selectedWorkshop !== null" class="workshop-detail">
              <b-form-group
                label="Nom de l'atelier"
                label-for="name"
              >
                <b-form-input
                  :disabled="readOnly"
                  id="name"
                  name="name"
                  v-model="selectedWorkshop.name"
                  @change="onChange()"
                >
                </b-form-input>
              </b-form-group>
              <b-form-group
                label="Prix de l'atelier"
                label-for="price"
              >
                <decimal-input
                  :disabled="readOnly"
                  id="price"
                  v-model="selectedWorkshop.price"
                  @change="onChange()"
                >
                </decimal-input>
              </b-form-group>
              <b-form-group
                label="Moment de la journée"
                label-for="moment"
              >
                <b-select id="moment" v-model="selectedWorkshop.moment" @change="onChange()" :disabled="readOnly">
                  <b-select-option v-for="elt of moments" :key="elt" :value="elt">
                    {{ getMomentName(elt) }}
                  </b-select-option>
                </b-select>
              </b-form-group>
              <b-form-group
                label="Nombre maximal de participants"
                label-for="max_number"
                description="Laissez 0 si pas de nombre maximal"
              >
                <b-form-input
                  :disabled="readOnly"
                  type="number"
                  step="1"
                  id="max_number"
                  name="max_number"
                  v-model="selectedWorkshop.maxNumber"
                  @change="onChange()"
                >
                </b-form-input>
              </b-form-group>
              <b-form-group
                label-for="discountable"
                description="Si coché, le prix d'atelier est impacté par les réductions en pourcentage des séances"
              >
                <b-checkbox
                  :disabled="readOnly"
                  v-model="selectedWorkshop.discountable"
                  name="discountable"
                  :value="true"
                  :unchecked-value="false"
                  @change="onChange()"
                >
                  Prend en compte les réductions en %
                </b-checkbox>
              </b-form-group>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
import { mapActions } from 'vuex'
import DecimalInput from '@/components/Controls/DecimalInput'
import { BackendMixin } from '@/mixins/backend'
import { makeWorkshop, WorkshopMoments } from '@/types/youth'
import { isNumber } from '@/utils/check'
import { BackendApi } from '@/utils/http'

export default {
  name: 'SeanceWorkshopsModal',
  components: {
    DecimalInput,
  },
  mixins: [BackendMixin],
  props: {
    seance: {
      type: Object,
      default: null,
    },
    seanceTemplate: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isSaveActive: true,
      workshops: [],
      nextWorkshopId: -1,
      selectedWorkshop: null,
      errors: '',
    }
  },
  watch: {
    obj: function() { this.loadWorkshops() },
  },
  computed: {
    moments() {
      return [
        WorkshopMoments.None,
        WorkshopMoments.Morning,
        WorkshopMoments.Lunch,
        WorkshopMoments.Afternoon,
        WorkshopMoments.Evening
      ]
    },
    readOnly() {
      return !this.hasPerm('youth.change_workshop')
    },
    obj() {
      if (this.seance) {
        return this.seance
      } else {
        return this.seanceTemplate
      }
    },
    url() {
      let url = ''
      if (this.seance) {
        url = '/api/youth/seances/' + this.seance.id + '/'
      } else {
        url = '/api/youth/seance-templates/' + this.seanceTemplate.id + '/'
      }
      return url
    },
    modalId() {
      if (this.seanceTemplate !== null) {
        return 'bv-modal-seance-template-workshops'
      } else {
        return 'bv-modal-seance-workshops'
      }
    },
  },
  methods: {
    ...mapActions(['addSuccess', 'addError']),
    onCancel(event) {
      this.selectedWorkshop = null
      this.$emit('modal-closed', {})
    },
    async onSave(event) {
      event.preventDefault()
      this.isSaveActive = false
      const backendApi = new BackendApi('patch', this.url)
      this.errors = ''
      try {
        let data = []
        for (let workshop of this.workshops) {
          let dataElt = {
            id: workshop.id > 0 ? workshop.id : 0,
            name: workshop.name,
            price: workshop.price,
            moment: workshop.moment,
            max_number: workshop.maxNumber,
            discountable: workshop.discountable,
          }
          data.push(dataElt)
        }
        const resp = await backendApi.callApi({ workshops: data, })
        this.$emit('refresh-required', {})
        if (resp.data.message) {
          await this.addSuccess(resp.data.message)
        }
        this.selectedWorkshop = null
        this.$bvModal.hide(this.modalId)
      } catch (err) {
        this.errors = this.getErrorText(err)
      }
    },
    async loadWorkshops() {
      this.workshops = this.obj.workshops.map(elt => { return { ...elt, } })
    },
    addWorkshop() {
      let workshop = makeWorkshop()
      workshop.id = this.nextWorkshopId
      this.nextWorkshopId -= 1
      this.workshops = this.workshops.concat([workshop])
      this.selectedWorkshop = workshop
      this.isSaveActive = this.isValid()
    },
    removeWorkshop(workshop) {
      let index = this.workshops.indexOf(workshop)
      if (index >= 0) {
        this.workshops.splice(index, 1)
        this.workshops = this.workshops.concat([])
        this.selectedWorkshop = null
      }
      this.isSaveActive = this.isValid()
    },
    selectWorkshop(workshop) {
      if (this.workshops.indexOf(workshop) >= 0) {
        this.selectedWorkshop = workshop
      } else {
        this.selectedWorkshop = null
      }
    },
    isValid() {
      for (let workshop of this.workshops) {
        if (
          (workshop.name === '') ||
          (!isNumber(workshop.price)) ||
          (!isNumber(workshop.maxNumber))
        ) {
          return false
        }
      }
      return true
    },
    onChange() {
      this.isSaveActive = this.isValid()
    },
    getMomentName(momentValue) {
      switch (momentValue) {
        case WorkshopMoments.None:
          return 'Indifférent'
        case WorkshopMoments.Morning:
          return 'Matin'
        case WorkshopMoments.Lunch:
          return 'Repas'
        case WorkshopMoments.Afternoon:
          return 'Après-midi'
        case WorkshopMoments.Evening:
          return 'Soirée'
        default:
          return '?'
      }
    },
  },
  mounted() {
    this.loadWorkshops()
  },
}
</script>
<style lang="less" scoped>
.workshop-line {
  padding: 10px;
  margin-bottom: 10px;
  background: #f0f0f0;
  cursor: pointer;
}
.workshop-line.selected {
  background: #d0d0d0;
}
</style>
