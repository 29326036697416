<template>
  <div class="delete-many-seances-modal" v-if="hasPerm('youth.delete_seance')">
    <b-modal
      size="lg"
      :id="modalId"
      cancel-title="Annuler"
      @cancel="onCancel"
      @ok="onSave"
      ok-variant="danger"
      :ok-title="okTitle"
      :ok-disabled="isLoading(loadingName) || (selected.length === 0)"
      @shown="onShow()"
    >
      <template v-slot:modal-title>
        Suppression multiple de séances
      </template>
      <b-form ref="form">
        <b-row>
          <b-col class="col-border" cols="4">
            <div v-if="!isLoading(loadingName) && selection.length">
              <div v-if="selected.length">
                <div>
                  <b>Confirmez la suppression</b>
                </div>
                <div>
                  <b>
                    <counter-label
                      :counter="selected.length"
                      label="séance sélectionnée"
                      label-n="séances sélectionnées"
                    ></counter-label>
                  </b>
                </div>
                <b></b>
              </div>
              <div v-else>
                <b>Veuillez sélectionner les séances à supprimer</b>
              </div>
              <loading-gif :loading-name="loadingName"></loading-gif>
              <div v-if="!isLoading(loadingName) && selected.length">
                <div class="error-text" v-if="errorText">
                  <i class="fa fa-warning"></i>
                  Erreur lors de vérification des inscriptions: {{ errorText }}
                </div>
                <div v-else>
                  <div class="warning-text" v-if="warningText">
                    <i class="fa fa-warning"></i>
                    {{ warningText }}
                  </div>
                  <div class="no-warning-text" v-if="noWarningText">
                    <i class="fa fa-check"></i>
                    {{ noWarningText }}
                  </div>
                </div>
              </div>
            </div>
          </b-col>
          <b-col>
            <div v-if="selection.length === 0">
              Aucune séance disponible
            </div>
            <div v-else>
              <check-box-select
                :choices="selection"
                @changed="onSelected"
                line-small
              >
              </check-box-select>
            </div>
          </b-col>
        </b-row>
      </b-form>
      <div class="confirm-text" v-if="warningText">
        <i class="fa fa-warning"></i>
        {{ warningText }}
      </div>
    </b-modal>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
import { mapActions, mapMutations } from 'vuex'
import CheckBoxSelect from '@/components/Controls/CheckBoxSelect'
import CounterLabel from '@/components/Controls/CounterLabel'
import LoadingGif from '@/components/Controls/LoadingGif'
import { BackendApi } from '@/utils/http'
import { BackendMixin } from '@/mixins/backend'

export default {
  name: 'DeleteManySeancesModal',
  components: {
    CounterLabel,
    LoadingGif,
    CheckBoxSelect,
  },
  mixins: [BackendMixin],
  props: {
    allSeances: Array,
    templates: Array,
  },
  data() {
    return {
      seances: [],
      selected: [],
      loadingName: 'delete-many-seances',
      errorText: '',
      hasInscriptions: false,
      hasInvoicedInscriptions: false,
      modalShown: false,
    }
  },
  watch: {
    allSeances: function() {
      if (this.modalShown) {
        this.loadBefore()
      }
    },
    templates: function() { },
  },
  computed: {
    filteredSeances() {
      return this.allSeances.filter(
        elt => {
          return (
            (this.codes.indexOf(elt.getCodeName()) >= 0)
          )
        }
      )
    },
    codes() {
      return this.templates.map(elt => elt.code)
    },
    selection() {
      return this.filteredSeances
    },
    modalId() {
      return 'bv-modal-delete-many-seances'
    },
    warningText() {
      if (this.hasInvoicedInscriptions) {
        let text = 'Les séances ont des inscriptions, dont certaines sont déjà facturées. '
        text += 'Leur suppression entraînera l\'annulation des inscriptions associées et la création '
        text += 'd\'un remboursement pour chaque inscription facturée'
        return text
      } else if (this.hasInscriptions) {
        return 'Les séances ont des inscriptions. Leur suppression entraînera l\'annulation des inscriptions associées'
      } else {
        return ''
      }
    },
    noWarningText() {
      if ((this.hasInvoicedInscriptions) || (this.hasInscriptions)) {
        return ''
      } else {
        return 'Aucune inscription pour ces séances'
      }
    },
    okTitle() {
      if (this.selected.length === 1) {
        return 'Supprimer une séance'
      } else if (this.selected.length > 1) {
        return 'Supprimer ' + this.selected.length + ' séances'
      }
      return 'Supprimer'
    },
  },
  methods: {
    ...mapActions(['addSuccess', 'addError']),
    ...mapMutations(['startLoading', 'endLoading']),
    onCancel(event) {
      this.$emit('modal-closed', {})
    },
    async onSave(event) {
      event.preventDefault()
      this.errorText = ''
      this.startLoading(this.loadingName)
      const url = '/api/youth/seances/delete_many/'
      const backendApi = new BackendApi('post', url)
      try {
        let data = {
          seances: this.selected.map(elt => elt.id),
        }
        const resp = await backendApi.callApi(data)
        this.$emit('refresh-required', {})
        if (resp.data.message) {
          await this.addSuccess(resp.data.message)
        }
        this.$bvModal.hide(this.modalId)
      } catch (err) {
        this.errorText = this.getErrorText(err)
      }
      this.endLoading(this.loadingName)
    },
    async loadBefore() {
      this.errorText = ''
      const url = '/api/youth/seances/before_delete_many/'
      this.startLoading(this.loadingName)
      const backendApi = new BackendApi('post', url)
      try {
        let data = {
          seances: this.selected.map(elt => elt.id),
        }
        const resp = await backendApi.callApi(data)
        this.hasInscriptions = (resp.data['inscriptions_count'] || 0) > 0
        this.hasInvoicedInscriptions = (resp.data['invoiced_inscriptions_count'] || 0) > 0
      } catch (err) {
        this.errorText = this.getErrorText(err)
      }
      this.endLoading(this.loadingName)
    },
    onSelected(selected) {
      this.selected = selected.choices
      this.loadBefore()
    },
    onShow() {
      this.selected = []
      this.loadBefore()
    },
  },
  mounted() {
  },
}
</script>
<style lang="less" scoped>
.confirm-text {
  padding: 10px;
  font-weight: bold;
  background: #fdacac;
  margin: 5px 0;
}
</style>
