<template>
  <div class="seance-fixed-fee-modal" v-if="hasPerm('youth.view_seance')">
    <b-modal
      size="lg"
      :id="modalId"
      cancel-title="Annuler"
      @cancel="onCancel"
      @ok="onSave"
      ok-variant="primary"
      ok-title="Enregistrer"
      :ok-disabled="readOnly"
    >
      <template v-slot:modal-title>
        <span v-if="seance">Gestion des forfaits</span>
      </template>
      <b-form ref="form" v-if="seance">
        <loading-gif :loading-name="loadingName"></loading-gif>
        <b-row v-if="!isLoading(loadingName)">
          <b-col class="col-border" cols="4">
            <b-row>
              <b-col>
                <div><b>{{ seance.name }}</b></div>
                <div v-if="seance.fixedFee">
                  Sélectionnez les séances à inclure dans le forfait
                </div>
                <div v-else>
                  Sélectionnez les forfait pour la séance
                </div>
              </b-col>
            </b-row>
          </b-col>
          <b-col>
            <div v-if="selection.length === 0">
              Aucune séance disponible
            </div>
            <div v-else>
              <check-box-select
                :choices="selection"
                :initial-value="initial"
                @changed="onSelected"
              >
              </check-box-select>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
import { mapActions, mapMutations } from 'vuex'
import CheckBoxSelect from '@/components/Controls/CheckBoxSelect'
import LoadingGif from '@/components/Controls/LoadingGif'
import { BackendApi } from '@/utils/http'
import { BackendMixin } from '@/mixins/backend'

export default {
  name: 'FixedFeeModal',
  components: {
    LoadingGif,
    CheckBoxSelect,
  },
  mixins: [BackendMixin],
  props: {
    seance: Object,
    allSeances: Array,
    templates: Array,
  },
  data() {
    return {
      packages: [],
      seances: [],
      selected: [],
      loadingName: 'fixed-fee',
    }
  },
  watch: {
    seance: function() { this.loadFixedFee() },
    initial: function() {
      this.selected = this.initial
    },
    templates: function() { },
  },
  computed: {
    readOnly() {
      return !this.hasPerm('youth.change_seance')
    },
    filteredSeances() {
      return this.allSeances.filter(
        elt => {
          return (
            (this.codes.indexOf(elt.getCodeName()) >= 0)
          )
        }
      )
    },
    codes() {
      return this.templates.map(elt => elt.code)
    },
    selection() {
      if (this.seance.fixedFee) {
        return this.filteredSeances.filter(elt => !elt.fixedFee)
      } else {
        return this.filteredSeances.filter(elt => elt.fixedFee)
      }
    },
    initial() {
      if (this.seance && this.seance.id) {
        if (this.seance.fixedFee) {
          return this.allSeances.filter(elt => !elt.fixedFee).filter(elt => this.seances.indexOf(elt.id) >= 0)
        } else {
          return this.allSeances.filter(elt => elt.fixedFee).filter(elt => this.packages.indexOf(elt.id) >= 0)
        }
      }
      return []
    },
    url() {
      let url = ''
      if (this.seance && this.seance.id) {
        url = '/api/youth/seance-fixed-fee/' + this.seance.id + '/'
      }
      return url
    },
    modalId() {
      return 'bv-modal-fixed-fee'
    },
  },
  methods: {
    ...mapActions(['addSuccess', 'addError']),
    ...mapMutations(['startLoading', 'endLoading']),
    onCancel(event) {
      this.$emit('modal-closed', {})
    },
    async onSave(event) {
      event.preventDefault()
      const backendApi = new BackendApi('post', this.url)
      try {
        let data = {
          all_seances: this.filteredSeances.map(elt => elt.id),
          seances: [],
          packages: [],
        }
        if (this.seance.fixedFee) {
          data.seances = this.selected.map(elt => elt.id)
        } else {
          data.packages = this.selected.map(elt => elt.id)
        }
        const resp = await backendApi.callApi(data)
        this.$emit('refresh-required', {})
        if (resp.data.message) {
          await this.addSuccess(resp.data.message)
        }
        this.$bvModal.hide(this.modalId)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async loadFixedFee() {
      if (this.url) {
        this.startLoading(this.loadingName)
        const backendApi = new BackendApi('get', this.url)
        try {
          const resp = await backendApi.callApi()
          this.packages = resp.data.packages
          this.seances = resp.data.seances
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.endLoading(this.loadingName)
      }
    },
    onSelected(selected) {
      this.selected = selected.choices
    },
  },
  mounted() {
    this.loadFixedFee()
  },
}
</script>
<style lang="less" scoped>

</style>
