<template>
  <span v-if="hasPerm('youth.view_seancetemplate')">
    <loading-gif :loading-name="seanceTemplatesLoading"></loading-gif>
    <div v-if="isFilterDefined">
      <div class="seance-template-header">
        <b-row>
          <b-col cols="6">
            <span class="seance-template-title">Gabarits de séance</span>&nbsp;
            <a href @click.prevent="showAll()">
              <i class="fa fa-angle-down" v-if="isAllShown()"></i>
              <i class="fa fa-angle-right" v-else></i>
            </a>
          </b-col>
          <b-col v-if="canEdit" style="margin-top: 5px">
            <a
              href @click.prevent="toggleEditMode()" class="btn btn-sm "
              :class="editMode ? 'btn-primary' : 'btn-secondary'"
            >
              <i class="fa fa-edit"></i> Modifier
            </a>
          </b-col>
          <b-col>
            <b-form-input v-model="codeFilter" placeholder="Code" class="ut-code-filter"></b-form-input>
          </b-col>
        </b-row>
      </div>
      <div v-if="filteredSeanceTemplates.length === 0">
        <i class="empty-text">Aucun gabarit de séance défini</i>
      </div>
      <div class="template-line template-line-header" v-if="filteredSeanceTemplates.length">
        <b-form-checkbox
          id="'template-check-all"
          :checked="areAllTemplatesSelected()"
          name="template-check-all"
          :value="true"
          :unchecked-value="false"
          class="ut-select-template-all"
          @change="selectAllTemplates($event)"
        >
         Tout cocher
        </b-form-checkbox>
      </div>
      <div v-for="seanceTemplate of filteredSeanceTemplates" :key="seanceTemplate.id">
        <div class="template-line ut-template-line">
          <b-row>
            <b-col>
              <b-form-checkbox
                :id="'template' + seanceTemplate.id"
                :checked="isTemplateSelected(seanceTemplate)"
                :name="'template' + seanceTemplate.id"
                :value="true"
                :unchecked-value="false"
                class="ut-select-template select-template"
                @change="selectTemplate(seanceTemplate, $event)"
              >
                {{ seanceTemplate.name }}
                <span class="small-badge badge-light">{{ seanceTemplate.code }}</span>
              </b-form-checkbox>
              <div class="view-element">
                <a href @click.prevent="showElement(seanceTemplate)">
                  <i class="fa fa-angle-down" v-if="isSeanceTemplateDetailVisible(seanceTemplate)"></i>
                  <i class="fa fa-angle-right" v-else></i>
                </a>
              </div>
            </b-col>
            <b-col v-if="editMode" class="text-right" cols="5">
              <a
                href
                v-if="canViewWorkshops && !seanceTemplate.fixedFee"
                @click.prevent="onEditSeanceTemplateWorkshops(seanceTemplate)"
                class="seance-button"
                :class="seanceTemplate.workshops.length ? 'seance-button-template-value' : ''"
                v-b-tooltip.hover.bottom="'Ateliers'"
              >
                <i class="fa fa-theater-masks"></i>
              </a>
              <a
                href
                v-if="showWelfare && canViewWelfare && !seanceTemplate.isReward"
                @click.prevent="onEditSeanceTemplateWelfare(seanceTemplate)"
                class="seance-button"
                :class="seanceTemplate.welfare.length ? 'seance-button-template-value' : ''"
                v-b-tooltip.hover.bottom="'Aides'"
              >
                <i class="fa fa-handshake"></i>
              </a>
              <a
                href
                v-if="canViewTariff"
                @click.prevent="onEditSeanceTemplateTariff(seanceTemplate)"
                class="seance-button"
                v-b-tooltip.hover.bottom="'Tarifs'"
              >
                <i class="fa fa-euro"></i>
              </a>
              <a
                href
                v-if="canChangeSeanceTemplate"
                @click.prevent="onEditSeanceTemplate(seanceTemplate)"
                class="seance-button seance-button-edit"
                v-b-tooltip.hover.bottom="'Modifier'"
              >
                <i class="fa fa-edit"></i>
              </a>
              <a
                v-if="canDelete"
                href
                @click.prevent="onDeleteSeanceTemplate(seanceTemplate)"
                class="seance-button seance-button-delete"
                v-b-tooltip.hover.bottom="'Supprimer'"
              >
                <i class="fa fa-times"></i>
              </a>
            </b-col>
          </b-row>
          <div class="template-detail" v-if="isSeanceTemplateDetailVisible(seanceTemplate)">
            <div class="warning-message" v-if="seanceTemplate.fixedFee">
              <b>
                Forfait: une séance fictive ouvrant gratuité pour l'inscription
                aux séances liées.
              </b>
            </div>
            <div><b>Moments: </b><seance-moment-indicator :seance="seanceTemplate"></seance-moment-indicator></div>
            <div><b>Durée: </b>{{ seanceTemplate.duration | round }}h</div>
            <div v-if="seanceTemplate.daysNumber > 1"><b>Nombre de jours: </b>{{ seanceTemplate.daysNumber }}</div>
            <div><b>Commune: </b>{{ seanceTemplate.citySpecificDisplay() }}</div>
            <div v-if="seanceTemplate.workshops.length">
              <b>Ateliers</b>:
              <span v-for="workshop of seanceTemplate.workshops" :key="workshop.id">{{ workshop.name }}</span>
            </div>
            <div v-if="seanceTemplate.welfare.length">
              <b>Aides</b>:
              <span v-for="welfare of seanceTemplate.welfare" :key="welfare.id">{{ welfare.name }}</span>&nbsp;
            </div>
          </div>
        </div>
      </div>
      <div v-if="editMode">
        <a
          href
          class="btn btn-sm btn-secondary"
          v-if="canAddSeanceTemplate"
          @click.prevent="onAddSeanceTemplate()"
          v-b-tooltip.hover.bottom="'Ajouter un gabarit de séance'"
        >
          <i class="fa fa-plus"></i> Ajouter
        </a>
      </div>
    </div>
    <seance-template-edit-modal
      :youth-home="youthHome"
      :seance-type="seanceType"
      :templates="filteredSeanceTemplates"
      :seanceTemplate="selectedSeanceTemplate"
      @updated="onSeanceTemplateUpdated($event)"
      @added="onSeanceTemplateAdded($event)"
    >
    </seance-template-edit-modal>
    <seance-workshops-modal
      :seance-template="selectedSeanceTemplate"
    >
    </seance-workshops-modal>
    <seance-tariff-modal
      :seance-template="selectedSeanceTemplate"
      :youth-home="youthHome"
      @refresh-required="onRefreshRequired()"
    >
    </seance-tariff-modal>
    <seance-welfare-modal
      :seance-template="selectedSeanceTemplate"
      :all-welfare="welfare"
    >
    </seance-welfare-modal>
    <confirm-modal
      name="delete-seance-template"
      :object="selectedSeanceTemplate"
      title="Suppression d'un gabarit de séances"
      :text="'Voulez-vous supprimer le gabarit ' + selectedSeanceTemplate.name + '?'"
      @confirmed="onDeleteSeanceTemplateConfirmed"
    ></confirm-modal>
  </span>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>

import { makeSeanceTemplate } from '@/types/youth'
import { BackendMixin } from '@/mixins/backend'
import { mapActions, mapMutations } from 'vuex'
import LoadingGif from '@/components/Controls/LoadingGif'
import { BackendApi } from '@/utils/http'
import ConfirmModal from '@/components/Modals/ConfirmModal'
import SeanceMomentIndicator from '@/components/Seances/SeanceMomentIndicator'
import SeanceTemplateEditModal from '@/components/Seances/Config/SeanceTemplateEditModal'
import SeanceWorkshopsModal from '@/components/Seances/Config/SeanceWorkshopsModal'
import SeanceTariffModal from '@/components/Seances/Config/SeanceTariffModal'
import SeanceWelfareModal from '@/components/Seances/Config/SeanceWelfareModal'

export default {
  name: 'SeanceTemplatesList',
  components: {
    ConfirmModal,
    SeanceTemplateEditModal,
    SeanceTariffModal,
    SeanceWorkshopsModal,
    SeanceWelfareModal,
    SeanceMomentIndicator,
    LoadingGif,
  },
  mixins: [BackendMixin],
  props: {
    youthHome: Object,
    seanceType: Object,
    welfare: Array,
  },
  data() {
    return {
      seanceTemplatesLoading: 'seanceTemplates',
      seanceTemplates: [],
      selectedTemplates: {},
      visibleTemplates: {},
      codeFilter: '',
      selectedSeanceTemplate: makeSeanceTemplate(),
      editMode: false,
    }
  },
  watch: {
    youthHome: function() { },
    seanceType: function() { },
    selectedTemplates: function() {
      this.emitSelection()
    },
    filteredSeanceTemplates: function() {
      this.emitSelection()
    },
  },
  computed: {
    isFilterDefined() {
      return (this.youthHome && this.seanceType)
    },
    filteredSeanceTemplates() {
      if (this.isFilterDefined) {
        return this.seanceTemplates.filter(
          elt => {
            return (
              ((this.codeFilter === '') || (elt.code.toLowerCase().indexOf(this.codeFilter.toLowerCase()) >= 0)) &&
              (elt.isAvailableFor(this.youthHome.number)) &&
              (elt.seanceType.id === this.seanceType.id)
            )
          }
        )
      } else {
        return []
      }
    },
    canChangeSeanceTemplate() {
      return this.hasPerm('youth.change_seancetemplate')
    },
    canAddSeanceTemplate() {
      return this.hasPerm('youth.add_seancetemplate')
    },
    canViewWorkshops() {
      return this.hasPerm('youth.view_workshop')
    },
    canViewTariff() {
      return this.hasPerm('youth.view_seancetariff')
    },
    canViewWelfare() {
      return this.hasPerm('tariffs.view_welfare')
    },
    showWelfare() {
      return this.welfare.length
    },
    canEdit() {
      return this.canChangeSeanceTemplate || this.canAddSeanceTemplate
    },
    canDelete() {
      return this.hasPerm('youth.delete_seancetemplate')
    },
  },
  methods: {
    ...mapActions(['addError']),
    ...mapMutations(['startLoading', 'endLoading']),
    onRefreshRequired() {
      this.loadSeanceTemplates()
    },
    async loadSeanceTemplates() {
      this.startLoading(this.seanceTemplatesLoading)
      let url = '/api/youth/seance-templates/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.seanceTemplates = resp.data.map(
          elt => {
            this.selectedTemplates[elt['id']] = true
            return makeSeanceTemplate(elt)
          }
        )
      } catch (err) {
        this.addError(this.getErrorText(err))
      }
      this.endLoading(this.seanceTemplatesLoading)
    },
    isTemplateSelected(seanceTemplate) {
      if (this.selectedTemplates.hasOwnProperty(seanceTemplate.id)) {
        return this.selectedTemplates[seanceTemplate.id]
      }
      return false
    },
    selectTemplate(seanceTemplate, value) {
      this.selectedTemplates[seanceTemplate.id] = value
      this.selectedTemplates = { ...this.selectedTemplates, }
    },
    isSeanceTemplateDetailVisible(seanceTemplate) {
      if (this.visibleTemplates.hasOwnProperty(seanceTemplate.id)) {
        return this.visibleTemplates[seanceTemplate.id]
      }
      return false
    },
    showAll() {
      const isAllShown = this.isAllShown()
      for (const seanceTemplate of this.seanceTemplates) {
        this.visibleTemplates[seanceTemplate.id] = !isAllShown
      }
      this.visibleTemplates = { ...this.visibleTemplates, }
    },
    isAllShown() {
      for (const seanceTemplate of this.seanceTemplates) {
        if (this.visibleTemplates.hasOwnProperty(seanceTemplate.id)) {
          if (!this.visibleTemplates[seanceTemplate.id]) {
            return false
          }
        } else {
          return false
        }
      }
      return true
    },
    showElement(seanceTemplate) {
      this.visibleTemplates[seanceTemplate.id] = !this.isSeanceTemplateDetailVisible(seanceTemplate)
      this.visibleTemplates = { ...this.visibleTemplates, }
    },
    areAllTemplatesSelected() {
      for (let template of this.filteredSeanceTemplates) {
        if (!this.isTemplateSelected(template)) {
          return false
        }
      }
      return true
    },
    selectAllTemplates(value) {
      for (let template of this.filteredSeanceTemplates) {
        this.selectTemplate(template, value)
      }
    },
    emitSelection() {
      let selection = []
      for (let template of this.filteredSeanceTemplates) {
        if (this.isTemplateSelected(template)) {
          selection.push(template)
        }
      }
      this.$emit('changed', { selection, all: this.filteredSeanceTemplates, })
    },
    onEditSeanceTemplate(seanceTemplate) {
      this.selectedSeanceTemplate = seanceTemplate
      this.$bvModal.show('bv-modal-edit-seance-template')
    },
    onDeleteSeanceTemplate(seanceTemplate) {
      this.selectedSeanceTemplate = seanceTemplate
      this.$bvModal.show('bv-confirm-modal:delete-seance-template')
    },
    async onDeleteSeanceTemplateConfirmed(event) {
      this.startLoading(this.seanceTemplatesLoading)
      let url = '/api/youth/seance-templates/' + event.object.id + '/'
      const backendApi = new BackendApi('delete', url)
      try {
        await backendApi.callApi()
        const index = this.seanceTemplates.map(elt => elt.id).indexOf(event.object.id)
        if (index >= 0) {
          this.seanceTemplates.splice(index, 1)
        }
      } catch (err) {
        this.addError(this.getErrorText(err))
      }
      this.endLoading(this.seanceTemplatesLoading)
    },
    onAddSeanceTemplate() {
      this.selectedSeanceTemplate = makeSeanceTemplate()
      this.$bvModal.show('bv-modal-edit-seance-template')
    },
    onEditSeanceTemplateWorkshops(seanceTemplate) {
      this.selectedSeanceTemplate = seanceTemplate
      this.$bvModal.show('bv-modal-seance-template-workshops')
    },
    onEditSeanceTemplateTariff(seanceTemplate) {
      this.selectedSeanceTemplate = seanceTemplate
      this.$bvModal.show('bv-modal-seance-template-tariff')
    },
    onEditSeanceTemplateWelfare(seanceTemplate) {
      this.selectedSeanceTemplate = seanceTemplate
      this.$bvModal.show('bv-modal-seance-template-welfare')
    },
    onSeanceTemplateUpdated(event) {
      const index = this.seanceTemplates.map(elt => elt.id).indexOf(event.id)
      if (index >= 0) {
        this.seanceTemplates[index] = event
      } else {
        this.seanceTemplates.push(event)
      }
      this.seanceTemplates = [].concat(this.seanceTemplates)
    },
    onSeanceTemplateAdded(event) {
      this.seanceTemplates.push(event)
    },
    toggleEditMode() {
      this.editMode = !this.editMode
    },
  },
  mounted() {
    this.loadSeanceTemplates()
  },
}
</script>
<style lang="less">
.template-line {
  margin-bottom: 5px;
  padding-bottom: 5px;
  border-bottom: solid #e0e0e0 1px;
  font-size: 14px;
}
.template-line-header {
  background: #e0e0e0;
  padding: 5px;
  font-style: italic;
}
.template-line:last-of-type {
  border-bottom: none;
}
.view-element {
  display: inline-block;
  text-align: right;
  margin-left: 10px;
}
.select-template {
  display: inline-block;
}
.template-detail {
  background: #e0e0e0;
  font-size: 12px;
  padding: 5px;
}
.seance-template-header {
  padding-bottom: 5px;
  margin-bottom: 5px;
  border-bottom: solid #e0e0e0 1px;
}
.seance-template-title {
  font-weight: bold;
  vertical-align: bottom;
  margin-top: 10px;
}
</style>
