<template>
  <div class="clone-seances-on-modal" v-if="periods.length && youthHome && seanceType">
    <loading-gif :loading-name="loadingName"></loading-gif>
    <div v-if="canView && isLoading">
      <b-modal
        :id="modalId"
        cancel-title="Annuler"
        @ok="onOk"
        ok-variant="primary"
        ok-title="Cloner"
        :ok-disabled="!isValid()"
      >
        <template v-slot:modal-title>
          <span><i class="fa fa-clone"></i> Cloner les séances</span>
        </template>
        <loading-gif :loading-name="loadingName"></loading-gif>
        <b-form ref="form">
          <b-row class="form-line">
            <b-col>
              <b-form-group
                :label="youthHomeLabel"
                label-for="youthHome"
                description=""
              >
                <b-input disabled :value="youthHome.name" name="youthHome"></b-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="form-line">
            <b-col>
              <b-form-group
                label="Type de séance"
                label-for="seanceType"
                description=""
              >
                <b-input disabled :value="seanceType.name" name="youthHome"></b-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Période"
                label-for="period"
                description=""
              >
                <b-input disabled :value="period.name" name="period"></b-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="form-line">
            <b-col cols="9">
              <check-box-select :choices="targetYouthHomes" @changed="onTargetSelected" id="targets">
              </check-box-select>
            </b-col>
            <b-col>
              <check-box-select :choices="options" @changed="onOptionsSelected" id="options">
              </check-box-select>
            </b-col>
          </b-row>
        </b-form>
      </b-modal>
    </div>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
import { mapActions, mapMutations } from 'vuex'
import CheckBoxSelect from '@/components/Controls/CheckBoxSelect'
import LoadingGif from '@/components/Controls/LoadingGif'
import { BackendMixin } from '@/mixins/backend'
import { makeChoice } from '@/types/base'
import { existsIn } from '@/utils/arrays'
import { BackendApi } from '@/utils/http'
import store from '@/store'

export default {
  name: 'CloneSeancesOnModal',
  components: {
    CheckBoxSelect,
    LoadingGif,
  },
  mixins: [BackendMixin],
  props: {
    youthHomes: Array,
    youthHome: Object,
    seanceType: Object,
    periods: Array,
    modalId: String,
    load: Boolean,
  },
  data() {
    return {
      selectedTargets: [],
      options: [],
      optionsMap: new Map(),
      selectedOptions: [],
      loadingName: 'CloneSeancesOnModal',
    }
  },
  watch: {
    youthHome: function() {},
    youthHomes: function() {},
    seanceType: function() {},
    periods: function() {},
  },
  computed: {
    youthHomeLabel() {
      return store.getters.youthHomeLabel
    },
    canView() {
      return this.hasPerm('youth.add_seance') && (this.youthHome.canCloneSeancesOn.length > 0)
    },
    loading() {
      return this.isLoading(this.loadingName)
    },
    period() {
      return this.periods[0]
    },
    targetYouthHomes() {
      return this.youthHomes.filter(
        elt => existsIn(
          [elt.number], this.youthHome.canCloneSeancesOn
        ) && (
          elt.number !== this.youthHome.number && elt.schoolYear.id === this.youthHome.schoolYear.id
        )
      )
    },
  },
  methods: {
    ...mapActions(['addSuccess', 'addError', 'addWarning']),
    ...mapMutations(['startLoading', 'endLoading']),
    async onOk(event) {
      event.preventDefault()
      await this.save()
      this.$bvModal.hide(this.modalId)
    },
    async save() {
      this.startLoading(this.loadingName)
      const options = []
      for (const option of this.selectedOptions) {
        options.push(this.optionsMap.get(option.id))
      }
      let data = {
        source: this.youthHome.id,
        targets: this.selectedTargets.map(elt => elt.id),
        options: options,
        period: this.period.id,
        seance_type: this.seanceType.id,
      }
      let backendApi = new BackendApi('post', '/api/youth/clone-youth-home-seances/')
      try {
        const resp = await backendApi.callApi(data)
        const createdCount = resp.data['created_count']
        const ignoredCount = resp.data['ignored_count']
        if (createdCount > 1) {
          await this.addSuccess('' + createdCount + ' séances créés')
        } else if (createdCount === 1) {
          await this.addSuccess('' + createdCount + ' séance créé')
        } else {
          await this.addWarning('Aucune séance créé')
        }
        if (ignoredCount > 1) {
          await this.addWarning('' + ignoredCount + ' séances déjà existantes')
        } else if (ignoredCount === 1) {
          await this.addWarning('' + ignoredCount + ' séance déjà existante')
        }
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
    },
    onTargetSelected(event) {
      this.selectedTargets = event.choices
    },
    onOptionsSelected(event) {
      this.selectedOptions = event.choices
    },
    async loadData() {
      this.startLoading(this.loadingName)
      this.endLoading(this.loadingName)
    },
    isValid() {
      return ((!this.isLoading(this.loadingName)) && (this.selectedTargets.length >= 1))
    },
  },
  created() {
    this.options = [
      makeChoice({ id: 1, name: 'Aides', }),
      makeChoice({ id: 2, name: 'Ateliers', }),
      makeChoice({ id: 3, name: 'Limites', })
    ]
    this.optionsMap.set(1, 'welfare')
    this.optionsMap.set(2, 'workshops')
    this.optionsMap.set(3, 'limits')
  },
}
</script>
<style lang="less" scoped>
</style>
