<template>
  <div class="" v-if="hasPerm('youth.change_seance')">
    <page-header title="Configuration des séances" icon="fa fa-list"></page-header>
    <seances-list-filter
      @changed="onListFilterChanged($event)"
      @loaded="onListFilterLoaded($event)"
    >
    </seances-list-filter>
    <div class="seances-config-main">
      <b-row>
        <b-col cols="4">
          <seance-templates-list
            :youth-home="selectedYouthHome"
            :seance-type="selectedSeanceType"
            :welfare="welfare"
            @changed="onSeanceTemplatesSelectionChanged($event)"
          >
          </seance-templates-list>
        </b-col>
        <b-col>
          <clone-seances-on-modal
            :youth-homes="youthHomes"
            :youth-home="selectedYouthHome"
            :seance-type="selectedSeanceType"
            :periods="selectedPeriods"
            modal-id="clone-seances-on-modal"
          >
          </clone-seances-on-modal>
          <add-many-seances-form
            v-if="addManySeances"
            :youth-home="selectedYouthHome"
            :seance-type="selectedSeanceType"
            :periods="selectedPeriods"
            :seance-templates="selectedTemplates"
            @cancel="addManySeances=false"
            @created="onSeancesCreated($event)"
          >
          </add-many-seances-form>
          <seances-list
            v-show="!addManySeances"
            :youth-home="selectedYouthHome"
            :seance-type="selectedSeanceType"
            :periods="selectedPeriods"
            :all-templates="allTemplates"
            :templates="selectedTemplates"
            :seances="seances"
            :refresh-counter="refreshSeancesRequired"
            :show-welfare="welfare.length > 0"
            @seances-loaded="seances = $event.seances"
            @edit-seance="onEditSeance($event)"
            @view-tariff="onViewTariff($event)"
            @view-limits="onViewLimits($event)"
            @view-workshops="onViewWorkshops($event)"
            @view-welfare="onViewWelfare($event)"
            @delete-seance="onDeleteSeance($event)"
            @add-one-seance="onAddOneSeance()"
            @add-many-seances="onAddManySeances()"
            @clone-seances="onCloneSeances()"
            @view-fixed-fee="onViewFixedFee($event)"
            @delete-many-seances="onViewDeleteManySeances()"
          >
          </seances-list>
        </b-col>
      </b-row>
    </div>
    <seances-detail-modal
      :youth-home="selectedYouthHome"
      :seance-type="selectedSeanceType"
      :periods="selectedSeanceDetail.id ? [selectedSeanceDetail.period] : selectedPeriods"
      :templates="selectedTemplates"
      :seance="selectedSeanceDetail"
      @seance-updated="onSeanceUpdated()"
      @seance-added="onSeanceAdded()"
    >
    </seances-detail-modal>
    <seance-tariff-modal
      :seance="selectedSeanceTariff"
      :youth-home="selectedYouthHome"
      @refresh-required="onRefreshRequired()"
    >
    </seance-tariff-modal>
    <seance-limits-modal
      :seance="selectedSeanceLimits"
      :age-groups="ageGroups"
      @modal-closed="clearSelectedSeance()"
      @refresh-required="onRefreshRequired()"
    >
    </seance-limits-modal>
    <seance-workshops-modal
      :seance="selectedSeanceWorkshops"
      @refresh-required="onRefreshRequired()"
      @modal-closed="clearSelectedSeance()"
    >
    </seance-workshops-modal>
    <seance-welfare-modal
      :seance="selectedSeanceWelfare"
      :all-welfare="welfare"
      @refresh-required="onRefreshRequired()"
      @modal-closed="clearSelectedSeance()"
    >
    </seance-welfare-modal>
    <fixed-fee-modal
      :seance="selectedSeanceFixedFee"
      :all-seances="seances"
      :templates="selectedTemplates"
      @refresh-required="onRefreshRequired()"
      @modal-closed="clearSelectedSeance()"
    >
    </fixed-fee-modal>
    <delete-many-seances-modal
      :all-seances="seances"
      :templates="selectedTemplates"
      @refresh-required="onRefreshRequired()"
      @modal-closed="clearSelectedSeance()"
    >
    </delete-many-seances-modal>
    <confirm-delete-seance-modal
      :object="selectedSeanceDelete"
      title="Suppression d'une séance"
      :text="'Voulez-vous supprimer la séance ' + selectedSeanceDelete.name + '?'"
      @confirmed="onConfirmDeleteSeance($event)"
    >
    </confirm-delete-seance-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapActions } from 'vuex'
import PageHeader from '@/components/Layout/PageHeader'
import AddManySeancesForm from '@/components/Seances/Config/AddManySeancesForm'
import ConfirmDeleteSeanceModal from '@/components/Seances/Config/ConfirmDeleteSeanceModal'
import SeancesDetailModal from '@/components/Seances/Config/SeanceEditModal'
import SeanceLimitsModal from '@/components/Seances/Config/SeanceLimitsModal'
import SeancesList from '@/components/Seances/Config/SeancesList'
import CloneSeancesOnModal from '@/components/Seances/Config/CloneSeancesOnModal'
import DeleteManySeancesModal from '@/components/Seances/Config/DeleteManySeancesModal'
import FixedFeeModal from '@/components/Seances/Config/FixedFeeModal'
import SeancesListFilter from '@/components/Seances/Config/SeancesListFilter'
import SeanceTariffModal from '@/components/Seances/Config/SeanceTariffModal'
import SeanceTemplatesList from '@/components/Seances/Config/SeanceTemplatesList'
import SeanceWelfareModal from '@/components/Seances/Config/SeanceWelfareModal'
import SeanceWorkshopsModal from '@/components/Seances/Config/SeanceWorkshopsModal'
import { BackendMixin } from '@/mixins/backend'
import { makeSeanceWelfare } from '@/types/tariffs'
import { makeAgeGroup, makeSeance } from '@/types/youth'
import { BackendApi } from '@/utils/http'

export default {
  name: 'Seances',
  mixins: [BackendMixin],
  components: {
    DeleteManySeancesModal,
    FixedFeeModal,
    CloneSeancesOnModal,
    ConfirmDeleteSeanceModal,
    AddManySeancesForm,
    SeanceWelfareModal,
    SeanceWorkshopsModal,
    SeanceLimitsModal,
    SeanceTariffModal,
    SeancesDetailModal,
    SeancesListFilter,
    SeanceTemplatesList,
    SeancesList,
    PageHeader,
  },
  data() {
    return {
      youthHomes: [],
      selectedYouthHome: null,
      selectedSeanceType: null,
      selectedPeriods: [],
      allTemplates: [],
      selectedTemplates: [],
      selectedSeanceDetail: makeSeance(),
      selectedSeanceTariff: makeSeance(),
      selectedSeanceWorkshops: makeSeance(),
      selectedSeanceLimits: makeSeance(),
      selectedSeanceWelfare: makeSeance(),
      selectedSeanceFixedFee: makeSeance(),
      selectedSeanceDelete: makeSeance(),
      seances: [],
      ageGroups: {},
      refreshSeancesRequired: 0,
      welfare: [],
      addManySeances: false,
    }
  },
  computed: {
  },
  mounted() {
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    onListFilterChanged(event) {
      if (this.selectedYouthHome !== event.youthHome) {
        this.loadWelfare(event.youthHome)
      }
      this.loadSeanceGroups(event.youthHome, event.seanceType, event.periods)
      this.selectedYouthHome = event.youthHome
      this.selectedSeanceType = event.seanceType
      this.selectedPeriods = event.periods
    },
    onListFilterLoaded(event) {
      this.youthHomes = event.youthHomes
    },
    onSeanceTemplatesSelectionChanged(event) {
      this.selectedTemplates = event.selection
      this.allTemplates = event.all
    },
    onEditSeance(event) {
      this.selectedSeanceDetail = event
      this.$bvModal.show('bv-modal-edit-seance')
    },
    viewModal(seance, modalName) {
      setTimeout(
        () => { this.$bvModal.show(modalName) },
        100
      )
    },
    onViewTariff(event) {
      this.selectedSeanceTariff = event
      this.viewModal(event, 'bv-modal-seance-tariff')
    },
    onViewLimits(event) {
      this.selectedSeanceLimits = event
      this.viewModal(event, 'bv-modal-seance-limits')
    },
    onViewWorkshops(event) {
      this.selectedSeanceWorkshops = event
      this.viewModal(event, 'bv-modal-seance-workshops')
    },
    onViewWelfare(event) {
      this.selectedSeanceWelfare = event
      this.viewModal(event, 'bv-modal-seance-welfare')
    },
    onViewFixedFee(event) {
      this.selectedSeanceFixedFee = event
      this.viewModal(event, 'bv-modal-fixed-fee')
    },
    onViewDeleteManySeances() {
      this.viewModal(null, 'bv-modal-delete-many-seances')
    },
    onDeleteSeance(event) {
      this.selectedSeanceDelete = event
      this.viewModal(event, 'bv-confirm-delete-seance-modal')
    },
    async onConfirmDeleteSeance(event) {
      let url = '/api/youth/seances/' + event.object.id + '/'
      let backendApi = new BackendApi('delete', url)
      try {
        await backendApi.callApi()
        await this.addSuccess('La séance a été supprimée')
        this.clearSelectedSeance()
        this.onRefreshRequired()
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    onSeanceUpdated() {
      this.onRefreshRequired()
    },
    onSeanceAdded() {
      this.onRefreshRequired()
    },
    async loadWelfare(youthHome) {
      if (youthHome && this.hasPerm('tariffs.view_welfare')) {
        let url = '/api/tariffs/welfare/' + youthHome.schoolYear.id + '/'
        let backendApi = new BackendApi('get', url)
        try {
          let resp = await backendApi.callApi()
          this.welfare = resp.data.map(elt => makeSeanceWelfare(elt))
        } catch (err) {
          this.welfare = []
          this.addError(this.getErrorText(err))
        }
      } else {
        this.welfare = []
      }
    },
    async loadSeanceGroups(youthHome, seanceType, periods) {
      if (youthHome && seanceType && periods && periods.length) {
        const ageGroups = {}
        let url = '/api/youth/seance-groups/' + youthHome.id + '/' + seanceType.id + '/?periods='
        url += periods.map(elt => elt.id).join(',')
        let backendApi = new BackendApi('get', url)
        try {
          let resp = await backendApi.callApi()
          for (const elt of resp.data) {
            ageGroups[elt.period] = elt.age_groups.map(elt2 => makeAgeGroup(elt2))
          }
        } catch (err) {
          this.addError(this.getErrorText(err))
        }
        this.ageGroups = ageGroups
      } else {
        this.ageGroups = {}
      }
    },
    onRefreshRequired() {
      this.refreshSeancesRequired += 1
    },
    clearSelectedSeance() {
      // clear the seance
      this.selectedSeanceDetail = makeSeance()
      this.selectedSeanceWorkshops = makeSeance()
      this.selectedSeanceWelfare = makeSeance()
      this.selectedSeanceFixedFee = makeSeance()
      this.selectedSeanceTariff = makeSeance()
      this.selectedSeanceLimits = makeSeance()
      this.selectedSeanceDelete = makeSeance()
    },
    onAddOneSeance() {
      this.selectedSeanceDetail = makeSeance()
      this.$bvModal.show('bv-modal-edit-seance')
    },
    onAddManySeances() {
      this.addManySeances = true
    },
    onCloneSeances() {
      this.$bvModal.show('clone-seances-on-modal')
    },
    onSeancesCreated() {
      this.addManySeances = false
      this.onRefreshRequired()
    },
  },
}
</script>

<style lang="less">
.seances-config-main {
  margin: 20px 0;
}
</style>
