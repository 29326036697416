
export type Maker = (elt: any) => any;

export function createMissingObj(array: any[], minNumber: number, maker: Maker, incIndex: boolean = false) {
  const length: number = array.length
  let missingObjs = []
  if (length < minNumber) {
    const range = (arrayLength: number) => { return Array.from(Array(arrayLength).keys()) }
    const newObjsCount = minNumber - length
    missingObjs = range(newObjsCount).map((elt) => maker({ id: -elt, }))
  }
  if (incIndex) {
    let baseIndex = 0
    if (array.length) {
      baseIndex = Math.max(...array.map(elt => elt.index)) + 1
    }
    for (let index = 0; index < missingObjs.length; index++) {
      const obj = missingObjs[index]
      obj.index = baseIndex + index
    }
  }
  return array.concat(missingObjs)
}
