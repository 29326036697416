<template>
  <div class="save-seance-modal" v-if="hasPerm('youth.change_seance')">
    <b-modal
      size="xl"
      id="bv-modal-edit-seance"
      cancel-title="Annuler"
      @ok="onSave"
      ok-variant="primary"
      ok-title="Enregistrer"
      :ok-disabled="!isSaveActive"
    >
      <template v-slot:modal-title>
        <span v-if="seance.id">Edition {{ seance.name }}</span>
        <span v-else>Ajout d'une nouvelle séance</span>
      </template>
      <b-form ref="form" v-if="isFilterDefined">
        <b-row class="form-line">
          <b-col>
            <b-form-group
              :label="youthHomeLabel"
              label-for="youth-home"
              description=""
            >
              <b-input id="youth-home" disabled :value="youthHome.name"></b-input>
            </b-form-group>
            <b-form-group
              label="Type"
              label-for="type"
              description=""
            >
              <b-input id="type" disabled :value="seanceType.name"></b-input>
            </b-form-group>
            <b-form-group
              label="Période"
              label-for="period"
              description=""
            >
              <b-select
                id="period"
                v-model="selectedPeriod"
                :disabled="seance.id > 0"
              >
                <b-select-option v-for="period of periods" :key="period.id" :value="period">
                  {{ period.name }}
                </b-select-option>
              </b-select>
            </b-form-group>
            <b-form-group
              label="Code"
              label-for="code"
              description=""
            >
              <b-form-input disabled type="text" :value="seanceCode" id="code" v-if="seance.id > 0">
              </b-form-input>
              <b-select
                id="code"
                v-model="selectedTemplate"
                v-if="seance.id === 0"
              >
                <b-select-option v-for="template of templates" :key="template.id" :value="template">
                  {{ template.code }} - {{ template.name }}
                </b-select-option>
              </b-select>
            </b-form-group>
            <b-form-group
              label="Nom"
              label-for="name"
              description=""
            >
              <b-form-input
                id="name"
                v-model="seanceName"
                @change="onChange()"
              ></b-form-input>
            </b-form-group>
            <b-row>
              <b-col>
                <b-form-group
                  label="Ordre"
                  label-for="order"
                  description="Tri croissant des séances. Matin(-10), Repas(0), Après-Midi(10), Soir(20)"
                >
                  <b-form-input
                    id="order"
                    type="number"
                    step="1"
                    min="0"
                    v-model="seance.order"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  label="Alias"
                  label-for="alias"
                  description="Si défini, Remplace le code séance dans l'affichage grille"
                >
                  <b-form-input
                    id="alias"
                    v-model="seance.alias"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col>
            <b-form-group
              label="Date"
              label-for="date"
              :description="getPeriodTimeframe()"
            >
              <b-form-input
                id="date"
                type="date"
                v-model="seanceDate"
                :readonly="seance.id > 0"
                @change="onDateChange()"
                :class="dateErrorClass()"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              v-if="showEndDate"
              label="Date de fin"
              label-for="date"
              :description="getEndDatePeriodTimeframe()"
            >
              <b-form-input
                id="endDate"
                type="date"
                v-model="seanceEndDate"
                @change="onChange()"
                :class="endDateErrorClass()"
                :disabled="!seanceDate"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              label="Type de séance"
              label-for="excursion"
              description="Standard / Sorties / Séjours"
            >
              <b-form-select
                id="excursion"
                v-model="seance.excursion"
              >
                <b-select-option v-for="elt of excursionChoices" :key="elt.id" :value="elt.id">
                  {{ elt.name }}
                </b-select-option>
              </b-form-select>
            </b-form-group>
            <b-form-group
              label="Modèles CAF"
              label-for="caf-models"
              :description="loadingCafModel ? 'chargement en cours' : 'Pour dossier CAF'"
            >
              <b-select
                id="caf-models"
                v-model="selectedCafModel"
                :disabled="!isCafModelActive || loadingCafModel"
                @change="onChange"
              >
                <b-select-option v-for="elt of cafModels" :key="elt.id" :value="elt">
                  {{ elt.fullName() }}
                </b-select-option>
              </b-select>
              <div v-if="showResetCafModel" class="reset-caf-warning">
                <i class="fa fa-exclamation-circle"></i>
                Les séances forfait ne devraient pas être associées à un modèle CAF
                <a href @click.prevent="resetCafModel()">réinitialiser</a>
              </div>
              <div class="caf-detail" v-if="selectedCafModel">
                <div class="caf-detail-item" v-if="selectedCafModel.convention.description">
                  {{ selectedCafModel.convention.description }}
                </div>
                <div class="caf-detail-item" v-if="selectedCafModel.seanceStartsAt || selectedCafModel.seanceEndsAt">
                  Matin: de {{ selectedCafModel.seanceStartsAt | defaultValue('-') }}
                  à {{ selectedCafModel.seanceEndsAt | defaultValue('-') }}
                </div>
                <div class="caf-detail-item" v-if="selectedCafModel.seanceStartsAt2 || selectedCafModel.seanceEndsAt2">
                  Après-midi de {{ selectedCafModel.seanceStartsAt2 | defaultValue('-') }}
                  à {{ selectedCafModel.seanceEndsAt2 | defaultValue('-') }}
                </div>
              </div>
            </b-form-group>
            <b-form-group
              label="Compte analytique"
              label-for="analyticAccount"
              description=""
            >
              <b-form-select
                id="analyticAccount"
                v-model="seanceAnalyticAccount"
                @change="onChange"
              >
                <b-select-option v-for="elt of analyticAccounts" :key="elt.id" :value="elt">
                  {{ elt.name }} <span v-if="elt.code">({{ elt.code }})</span>
                </b-select-option>
              </b-form-select>
            </b-form-group>
            <b-form-group
              label="Commentaires"
              label-for="comments"
              description="affiché sur le portail Famille"
            >
              <b-form-input
                id="comments"
                v-model="seance.comments"
              ></b-form-input>
            </b-form-group>
            <div class="">
              <div class="warning-message" v-if="seance.fixedFee">
                <b>
                  La séance est un forfait: une séance fictive ouvrant gratuité pour l'inscription
                  aux séances liées.
                </b>
                <div v-if="seance.fixedFeeAuto">
                  l'inscription au forfait créera automatiquement les inscriptions aux séances liées.
                </div>
              </div>
              <div class="warning-message" v-if="seance.isReward">
                <b>
                  La séance est un chantier: une séance qui n'est pas dû mais dont
                  le montant est crédité sur une cagnotte.
                </b>
              </div>
              <div><b>Moments</b>: <seance-moment-indicator :seance="seance"></seance-moment-indicator></div>
              <div><b>Durée</b>: {{ seance.duration | round }}h</div>
              <div><b>Commune</b>: {{ seance.citySpecificDisplay() }}</div>
              <div>
                <b>Code analytique</b>: {{ seanceAnalyticAccount.code || seanceAnalyticAccount.name }}
              </div>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
import moment from 'moment'
import { mapActions } from 'vuex'
import SeanceMomentIndicator from '@/components/Seances/SeanceMomentIndicator'
import { BackendMixin } from '@/mixins/backend'
import { CitySpecific, makeSeance, makeSeanceAnalyticAccount, getExcursionChoices } from '@/types/youth'
import { BackendApi } from '@/utils/http'
import { isInTimeframe, diffDate } from '@/utils/dates'
import { makeCafModel } from '@/types/reports'

import store from '@/store'

export default {
  name: 'SeancesDetailModal',
  components: {
    SeanceMomentIndicator,
  },
  mixins: [BackendMixin],
  props: {
    seance: Object,
    youthHome: Object,
    seanceType: Object,
    periods: {
      type: Array,
      defaultValue: [],
    },
    templates: Array,
  },
  data() {
    return {
      seanceName: '',
      selectedTemplate: null,
      seanceCode: '',
      seanceDate: '',
      seanceEndDate: '',
      isSaveActive: true,
      selectedPeriod: null,
      analyticAccounts: [],
      seanceAnalyticAccount: null,
      selectedCafModel: null,
      cafModels: [],
      seanceId: 0,
      error: false,
      loadingCafModel: false,
      excursionChoices: getExcursionChoices(),
    }
  },
  watch: {
    seance: function(seance) {
      this.initValues(seance)
    },
    youthHome: function() { },
    seanceType: function() { },
    periods: function() { },
    templates: function() { },
    seanceCode: function(code) {
      let matchingTemplates = this.templates.filter(elt => elt.code === code)
      if (matchingTemplates.length) {
        let template = matchingTemplates[0]
        if (this.seance.getCodeName() !== template.code) {
          // Si on change le code : alors on met à jour les infos
          this.seanceName = template.name
          this.seance.morning = template.morning
          this.seance.lunch = template.lunch
          this.seance.picnic = template.picnic
          this.seance.afternoon = template.afternoon
          this.seance.evening = template.evening
          this.seance.citySpecific = template.citySpecific
          this.seance.duration = template.duration
          this.seance.order = template.order
          this.seance.alias = template.alias
          this.seance.fixedFee = template.fixedFee
          this.seance.fixedFeeAuto = template.fixedFeeAuto
          this.seance.isReward = template.isReward
          this.seance.excursion = template.excursion
          this.seance.preferenceGroup = template.preferenceGroup
          this.onChange()
        }
      }
    },
    selectedTemplate: function() {
      if (this.selectedTemplate) {
        this.seanceCode = this.selectedTemplate.code
        if (this.seance.id === 0) {
          this.loadTemplateCafModel()
        }
      } else {
        this.seanceCode = ''
      }
    },
  },
  computed: {
    youthHomeLabel() {
      return store.getters.youthHomeLabel
    },
    showEndDate() {
      return (this.seance.id === 0) && (this.selectedTemplate) && (this.selectedTemplate.daysNumber > 1)
    },
    isFilterDefined() {
      return this.seanceType && this.youthHome && this.selectedPeriod
    },
    citySpecificChoices() {
      return [
        { value: CitySpecific.Yes, label: 'Seulement Commune', },
        { value: CitySpecific.No, label: 'Seulement Hors-Commune', },
        { value: CitySpecific.NA, label: 'Toutes les communes', }
      ]
    },
    canViewCafModel() {
      return this.hasPerm('reports.view_cafmodel')
    },
    canChangeCafModel() {
      return this.hasPerm('reports.change_cafmodel')
    },
    isCafModelActive() {
      return this.canChangeCafModel && !this.seance.fixedFee
    },
    showResetCafModel() {
      return this.seance.fixedFee && !(
        (this.selectedCafModel === null) || (this.selectedCafModel.id === 0)
      )
    },
  },
  methods: {
    ...mapActions(['addSuccess', 'addError']),
    resetCafModel() {
      const matching = this.cafModels.filter(elt => elt.id === 0)
      if (matching.length) {
        this.selectedCafModel = matching[0]
      }
    },
    async initValues(seance) {
      if (seance.date) {
        this.seanceDate = moment(seance.date).format('YYYY-MM-DD')
      } else {
        this.seanceDate = ''
      }
      this.seanceName = seance.getShortName()
      this.seanceCode = seance.getCodeName()
      this.seanceAnalyticAccount = seance.seanceAnalyticAccount
      this.seanceId = seance.id
      this.selectedCafModel = null
      this.loadingCafModel = true
      await this.loadCafModels()
      if (seance.id === 0) {
        if (this.periods.length === 1) {
          this.selectedPeriod = this.periods[0]
        } else {
          this.selectedPeriod = seance.period
        }
        if (this.templates.length === 1) {
          this.selectedTemplate = this.templates[0]
          this.seanceCode = this.selectedTemplate.code
          if (this.seance.id === 0) {
            await this.loadTemplateCafModel()
          }
        } else {
          this.selectedTemplate = null
        }
      } else {
        this.selectedPeriod = seance.period
      }
      await this.loadSeanceCafModel()
      await this.loadSeanceAnalyticAccounts()
      if (this.seance.id === 0) {
        this.seanceAnalyticAccount = this.analyticAccounts[0]
      }
      this.onChange()
    },
    async onSave() {
      await this.saveSeance()
      if (!this.error) {
        await this.saveCafModel()
      }
    },
    async saveSeance() {
      this.seances = []
      let backendApi = null
      let url = '/api/youth/seances/'
      let data = {}
      data.morning = this.seance.morning
      data.lunch = this.seance.lunch
      data.picnic = this.seance.picnic
      data.afternoon = this.seance.afternoon
      data.evening = this.seance.evening
      data.date = this.seance.date
      data.name = this.seance.getLongName(this.seance.date, this.seanceName)
      data.code = this.seance.getLongCode(this.seance.date, this.seanceCode, this.youthHome.number)
      data.city_specific = this.seance.citySpecific
      data.duration = this.seance.duration
      data.comments = this.seance.comments
      data.order = this.seance.order
      data.alias = this.seance.alias
      data.fixed_fee = this.seance.fixedFee
      data.fixed_fee_auto = this.seance.fixedFeeAuto
      data.is_reward = this.seance.isReward
      data.excursion = this.seance.excursion
      data.preference_group = this.seance.preferenceGroup
      data['seance_analytic_account'] = this.seanceAnalyticAccount.id
      if (this.seance.id) {
        url += '' + this.seance.id + '/'
        backendApi = new BackendApi('patch', url)
      } else {
        data.seance_type = this.seanceType.id
        data.period = this.selectedPeriod.id
        data.youth_home = this.youthHome.id
        if (this.showEndDate) {
          data.end_date = this.seanceEndDate
        }
        backendApi = new BackendApi('post', url)
      }
      try {
        let resp = await backendApi.callApi(data)
        let seance = makeSeance(resp.data)
        this.seanceId = seance.id
        let successText = ''
        if (this.seance.id) {
          this.$emit('seance-updated', seance)
          successText = 'La séance a été modifiée'
        } else {
          this.$emit('seance-added', seance)
          successText = 'La séance a été ajoutée'
        }
        await this.addSuccess(successText)
        this.error = false
      } catch (err) {
        this.error = true
        await this.addError(this.getErrorText(err))
      }
    },
    getPeriodTimeframe(startDate = null) {
      if (this.selectedPeriod && this.youthHome) {
        for (const timeframe of this.selectedPeriod.timeframes) {
          if (timeframe.schoolYearId === this.youthHome.schoolYear.id) {
            let dateFrom = timeframe.dateFrom
            if (startDate && diffDate(startDate, dateFrom) > 0) {
              dateFrom = startDate
            }
            const fromStr = moment(dateFrom).format('ll')
            const toStr = moment(timeframe.dateTo).format('ll')
            return 'Du ' + fromStr + ' au ' + toStr
          }
        }
      }
      return ''
    },
    getEndDatePeriodTimeframe() {
      return this.getPeriodTimeframe(this.seanceDate)
    },
    isInPeriodTimeframe(seanceDate) {
      if (seanceDate) {
        if (this.selectedPeriod && this.youthHome) {
          for (const timeframe of this.selectedPeriod.timeframes) {
            if (timeframe.schoolYearId === this.youthHome.schoolYear.id) {
              return isInTimeframe(seanceDate, timeframe.dateFrom, timeframe.dateTo)
            }
          }
        }
        return true
      }
      return false
    },
    isValid() {
      if (this.seanceDate) {
        const text = ('' + this.seanceDate)
        if (text !== 'Invalid date') {
          this.seance.date = moment(this.seanceDate).format('YYYY-MM-DD')
        } else {
          this.seance.date = null
        }
      } else {
        this.seance.date = null
      }
      if (!this.isInPeriodTimeframe(this.seance.date)) {
        this.seance.date = null
      }
      return !!(
        this.seanceCode &&
        this.seance.date &&
        this.seanceName &&
        this.seanceAnalyticAccount !== null &&
        this.selectedCafModel !== null &&
        this.endDateErrorClass() === ''
      )
    },
    dateErrorClass() {
      if (this.seanceDate && !this.isInPeriodTimeframe(this.seanceDate)) {
        return 'input-error'
      }
      return ''
    },
    endDateErrorClass() {
      let hasError = false
      if (this.showEndDate && this.seanceEndDate) {
        if (!this.isInPeriodTimeframe(this.seanceEndDate)) {
          hasError = true
        } else if (this.seanceDate) {
          const delta = diffDate(this.seanceEndDate, this.seanceDate)
          hasError = (delta < 0)
        }
      }
      return hasError ? 'input-error' : ''
    },
    onDateChange() {
      if (this.showEndDate) {
        this.seanceEndDate = moment(this.seanceDate).add(
          this.selectedTemplate.daysNumber - 1, 'days'
        ).format('YYYY-MM-DD')
      }
      this.onChange()
    },
    onChange() {
      this.isSaveActive = this.isValid()
    },
    async loadSeanceAnalyticAccounts() {
      let url = '/api/youth/seance-analytic-accounts/'
      let backendApi = new BackendApi('get', url)
      try {
        let resp = await backendApi.callApi()
        this.analyticAccounts = [makeSeanceAnalyticAccount({ id: 0, name: 'Par défaut', })].concat(
          resp.data.map(elt => makeSeanceAnalyticAccount(elt))
        )
      } catch (err) {
        this.addError(this.getErrorText(err))
      }
    },
    async saveCafModel() {
      if (this.canChangeCafModel) {
        const url = '/reports/api/caf-model/seance/' + this.seanceId + '/'
        const backendApi = new BackendApi('post', url)
        let data = {
          'caf_model': this.selectedCafModel ? this.selectedCafModel.id : 0,
        }
        try {
          await backendApi.callApi(data)
        } catch (err) {
          this.addError(this.getErrorText(err))
        }
      }
    },
    async loadCafModels() {
      const url = '/reports/api/caf-models/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.cafModels = [makeCafModel({ id: 0, })].concat(
          resp.data.map(
            elt => {
              return makeCafModel(elt)
            }
          )
        )
      } catch (err) {
        this.addError('Modèle CAF: ' + this.getErrorText(err))
      }
    },
    async loadSeanceCafModel() {
      this.selectedCafModel = null
      if (this.seance.id) {
        const url = '/reports/api/caf-model/seance/' + this.seance.id + '/'
        const backendApi = new BackendApi('get', url)
        try {
          const resp = await backendApi.callApi()
          const cafModelId = resp.data['caf_model'] || 0
          const cafModelIndex = this.cafModels.map(elt => elt.id).indexOf(cafModelId)
          if (cafModelIndex >= 0) {
            this.selectedCafModel = this.cafModels[cafModelIndex]
          }
        } catch (err) {
          this.addError('Modèle CAF: ' + this.getErrorText(err))
        }
      }
      this.loadingCafModel = false
    },
    async loadTemplateCafModel() {
      this.selectedCafModel = null
      if (this.selectedTemplate.id) {
        const url = '/reports/api/caf-model/seance-template/' + this.selectedTemplate.id + '/'
        const backendApi = new BackendApi('get', url)
        try {
          const resp = await backendApi.callApi()
          const cafModelId = resp.data['caf_model'] || 0
          const cafModelIndex = this.cafModels.map(elt => elt.id).indexOf(cafModelId)
          if (cafModelIndex >= 0) {
            this.selectedCafModel = this.cafModels[cafModelIndex]
          }
        } catch (err) {
          this.addError('Modèle CAF: ' + this.getErrorText(err))
        }
      }
    },
  },
  created() {
    this.initValues(this.seance)
    this.isSaveActive = this.isValid()
  },
}
</script>
<style lang="less" scoped>
.form-group:last-of-type {
  border-bottom: none;
}
.input-error {
  background: #ff4444;
}
.caf-detail {
  font-size: 12px;
  color: #222;
}
.caf-detail-item {
  display: inline-block;
  padding: 3px;
  background: #eee;
  border: solid 1px #ccc;
  margin-right: 10px;
  margin-top: 3px;
}
.warning-message {
  background: #ffb871;
  color: #000;
  font-size: 12px;
  padding: 3px;
}
.reset-caf-warning {
  padding: 5px;
  background: #fcc;
  margin: 2px 0;
}
</style>
