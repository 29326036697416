<template>
  <div class="seance-welfare-modal" v-if="hasPerm('tariffs.view_welfare')">
    <b-modal
      size="lg"
      :id="modalId"
      cancel-title="Annuler"
      @cancel="onCancel"
      @ok="onSave"
      ok-variant="primary"
      ok-title="Enregistrer"
      :ok-disabled="readOnly"
    >
      <template v-slot:modal-title>
        <span v-if="obj.id">Aides {{ obj.name }}</span>
      </template>
      <b-form ref="form" v-if="obj.id">
        <b-row>
          <b-col class="col-border" cols="8">
            <b-row>
              <b-col>
                <h4 v-if="seance">Aides de la séance</h4>
                <h4 v-else>Aides du gabarit de séances</h4>
              </b-col>
            </b-row>
            <b-row v-if="seanceWelfare.length === 0">
              <b-col><div class="empty-text">Aucune aide n'est définie</div></b-col>
            </b-row>
            <b-row>
              <b-col>
                <div
                  v-for="welfare of seanceWelfare"
                  :key="welfare.id"
                  class="welfare-line"
                >
                  <b-row>
                    <b-col cols="4">
                      {{ welfare.name }}
                      <div v-if="welfare.organizationName" class="small-text">
                        {{ welfare.organizationName }}
                      </div>
                    </b-col>
                    <b-col cols="3">
                      {{ welfare.welfareType.name }}
                    </b-col>
                    <b-col cols="3">
                      {{ welfare.getWelfareAmount() }}
                    </b-col>
                    <b-col cols="2" class="text-right">
                      <a v-if="!readOnly" class="btn btn-danger btn-sm" @click.prevent="removeWelfare(welfare)">
                        <i class="fa fa-times"></i>
                      </a>
                    </b-col>
                  </b-row>
                  <b-row v-if="welfare.allowedInFixedFeeSeances">
                    <b-col>
                      <div class="small-text">
                        La réduction est autorisée sur les séances incluses dans un forfait.
                        <i
                          class="fa fa-info-circle"
                          title="Le prix d'inscription est négatif dans ce cas."
                          v-b-tooltip
                        >
                        </i>
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="4" v-if="!readOnly">
            <div class="welfare-detail">
              <b-form-group
                label="Aides possibles"
                label-for="welfare"
                v-if="allWelfare.length"
              >
                <b-select id="welfare" v-model="selectedWelfare">
                  <b-select-option
                    v-for="elt of allWelfare"
                    :key="elt.id"
                    :value="elt"
                    :disabled="!canSelectWelfare(elt)"
                  >
                    {{ elt.name }}
                  </b-select-option>
                </b-select>
              </b-form-group>
              <div v-else>
                <b>Aucune aide disponible</b>
              </div>
              <div v-if="selectedWelfare !== null">
                <b-list-group class="welfare-info">
                  <b-list-group-item>{{ selectedWelfare.name }}</b-list-group-item>
                  <b-list-group-item>{{ selectedWelfare.getWelfareAmount() }}</b-list-group-item>
                  <b-list-group-item>{{ selectedWelfare.welfareType.name }}</b-list-group-item>
                  <b-list-group-item v-if="selectedWelfare.allowedInFixedFeeSeances">
                    La réduction est autorisée sur les séances incluses dans un forfait.<br />
                    Le prix d'inscription est négatif dans ce cas.
                  </b-list-group-item>
                  <b-list-group-item v-if="selectedWelfare.organizationName">
                    {{ selectedWelfare.organizationName }}
                  </b-list-group-item>
                </b-list-group>
                <b-row>
                  <b-col class="text-right">
                    <a href class="btn btn-primary btn-sm ut-add-welfare" @click.prevent="addWelfare()">
                      <i class="fa fa-plus"></i> Ajouter
                    </a>
                  </b-col>
                </b-row>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
import { mapActions } from 'vuex'
import { BackendApi } from '@/utils/http'
import { BackendMixin } from '@/mixins/backend'

export default {
  name: 'SeanceWelfareModal',
  components: {
  },
  mixins: [BackendMixin],
  props: {
    seance: {
      type: Object,
      default: null,
    },
    seanceTemplate: {
      type: Object,
      default: null,
    },
    allWelfare: Array,
  },
  data() {
    return {
      seanceWelfare: [],
      nextWelfareId: -1,
      selectedWelfare: null,
    }
  },
  watch: {
    obj: function() { this.loadWelfare() },
    allWelfare: function() { },
  },
  computed: {
    readOnly() {
      return !this.hasPerm('tariffs.change_welfare')
    },
    obj() {
      if (this.seance) {
        return this.seance
      } else {
        return this.seanceTemplate
      }
    },
    url() {
      let url = ''
      if (this.seance) {
        url = '/api/youth/seances/' + this.seance.id + '/'
      } else {
        url = '/api/youth/seance-templates/' + this.seanceTemplate.id + '/'
      }
      return url
    },
    modalId() {
      if (this.seanceTemplate !== null) {
        return 'bv-modal-seance-template-welfare'
      } else {
        return 'bv-modal-seance-welfare'
      }
    },
  },
  methods: {
    ...mapActions(['addSuccess', 'addError']),
    onCancel(event) {
      this.selectedWelfare = null
      this.$emit('modal-closed', {})
    },
    async onSave(event) {
      event.preventDefault()
      const backendApi = new BackendApi('patch', this.url)
      try {
        let data = this.seanceWelfare.map(elt => elt.id)
        const resp = await backendApi.callApi({ welfare: data, })
        this.$emit('refresh-required', {})
        if (resp.data.message) {
          await this.addSuccess(resp.data.message)
        }
        this.$bvModal.hide(this.modalId)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async loadWelfare() {
      this.seanceWelfare = this.obj.welfare.map(elt => Object.create(elt))
    },
    addWelfare() {
      if (this.selectedWelfare) {
        this.seanceWelfare = this.seanceWelfare.concat([this.selectedWelfare])
        this.selectedWelfare = null
      }
    },
    removeWelfare(welfare) {
      let index = this.seanceWelfare.indexOf(welfare)
      if (index >= 0) {
        this.seanceWelfare.splice(index, 1)
        this.seanceWelfare = this.seanceWelfare.concat([])
        this.selectedWelfare = null
      }
    },
    canSelectWelfare(welfare) {
      // Si une aide est déjà sélectionnée alors on ne peut pas la sélectionner à nouveau
      return this.seanceWelfare.map(elt => elt.id).indexOf(welfare.id) < 0
    },
  },
  mounted() {
    this.loadWelfare()
  },
}
</script>
<style lang="less" scoped>
.welfare-line {
  padding: 10px;
  margin-bottom: 10px;
  background: #f0f0f0;
  cursor: pointer;
}
.welfare-line.selected {
  background: #d0d0d0;
}
.welfare-info {
  margin-bottom: 20px;
}
</style>
