<template>
  <div class="save-tariff-modal" v-if="hasPerm('youth.view_seancetariff')">
    <b-modal
      size="xl"
      :id="modalId"
      cancel-title="Annuler"
      @ok="onSave"
      ok-variant="primary"
      ok-title="Enregistrer"
      :ok-disabled="!isSaveActive || readOnly"
    >
      <template v-slot:modal-title>
        <span v-if="obj.id">Tarif {{ obj.name }}</span>
        &nbsp;&nbsp;<span class="badge badge-dark">{{ tariffTypeLabel }}</span>
        &nbsp;<span
          class="badge badge-warning"
          v-if="isReward"
          title="Le montant n'est pas dû mais crédité sur une cagnotte"
          v-b-tooltip
        >
          Chantier
        </span>
      </template>
      <b-form ref="form">
        <seance-tariff-editor
          :seance-model="seanceModel"
          :seance-id="obj.id"
          :youthHome="youthHome"
          :seance-type="obj.seanceType"
          :duration="obj.duration"
          :custom-scale-id="customScaleId"
          :read-only="readOnly"
          @tariff-changed="onTarifChanged($event)"
        >
        </seance-tariff-editor>
      </b-form>
    </b-modal>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>

import { mapActions } from 'vuex'
import SeanceTariffEditor from '@/components/Seances/Config/SeanceTariffEditor'
import { BackendMixin } from '@/mixins/backend'
import { getTariffTypeLabel } from '@/types/youth'
import { BackendApi } from '@/utils/http'

export default {
  name: 'SeanceTariffModal',
  mixins: [BackendMixin],
  components: {
    SeanceTariffEditor,
  },
  props: {
    seance: {
      type: Object,
      default: null,
    },
    seanceTemplate: {
      type: Object,
      default: null,
    },
    youthHome: Object,
  },
  data() {
    return {
      isSaveActive: false,
      seanceTariff: null,
      customScale: null,
    }
  },
  watch: {
    obj: function() {},
    youthHome: function() {},
  },
  computed: {
    readOnly() {
      return !this.hasPerm('youth.change_seancetariff')
    },
    obj() {
      if (this.seance) {
        return this.seance
      } else {
        return this.seanceTemplate
      }
    },
    seanceModel() {
      if (this.seance) {
        return 'seance'
      } else {
        return 'seance-template'
      }
    },
    isReward() {
      if (this.seance) {
        return this.seance.isReward
      } else {
        return this.seanceTemplate.isReward
      }
    },
    customScaleId() {
      return this.obj ? this.obj.scale : null
    },
    tariffUrl() {
      let url = ''
      if (this.seance) {
        url = '/api/youth/tariff/seance/' + this.seance.id + '/'
      } else {
        url = '/api/youth/tariff/seance-template/' + this.seanceTemplate.id + '/'
      }
      return url
    },
    scaleUrl() {
      let url = ''
      if (this.seance) {
        url = '/api/youth/scale/seance/' + this.seance.id + '/'
      } else {
        url = '/api/youth/scale/seance-template/' + this.seanceTemplate.id + '/'
      }
      return url
    },
    modalId() {
      if (this.seanceTemplate !== null) {
        return 'bv-modal-seance-template-tariff'
      } else {
        return 'bv-modal-seance-tariff'
      }
    },
    tariffTypeLabel() {
      if (this.obj.forceTariffType) {
        return getTariffTypeLabel(this.obj.customTariffType)
      } else {
        return this.obj.seanceType.getTariffTypeLabel()
      }
    },
  },
  methods: {
    ...mapActions(['addSuccess', 'addError']),
    async onSave(event) {
      event.preventDefault()
      this.isSaveActive = false
      const backendApi = new BackendApi('post', this.url)
      try {
        await this.doSaveCustomScale()
        await this.doSaveTariff()
      } catch (err) {
        this.isSaveActive = true
        await this.addError(this.getErrorText(err))
      }
    },
    async doSaveTariff() {
      const backendApi = new BackendApi('post', this.tariffUrl)
      let data = this.seanceTariff.toData()
      const resp = await backendApi.callApi(data)
      if (resp.data.message) {
        await this.addSuccess(resp.data.message)
      }
      this.$emit('refresh-required', {})
      this.$bvModal.hide(this.modalId)
    },
    async doSaveCustomScale() {
      const newCustomScaleId = (this.customScale !== null) ? this.customScale.id : 0
      const changed = (newCustomScaleId !== this.customScaleId)
      if (changed) {
        const backendApi = new BackendApi('post', this.scaleUrl)
        try {
          let data = {
            scale: (this.customScale !== null) ? this.customScale.id : null,
          }
          await backendApi.callApi(data)
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
    onTarifChanged(event) {
      if (event.tariff) {
        this.seanceTariff = event.tariff
        this.customScale = event.customScale
        this.isSaveActive = this.seanceTariff.toData() !== null
      }
    },
  },
  mounted() {},
}
</script>
<style lang="less" scoped>
</style>
